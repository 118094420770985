import React from "react";
import VerifyLink from "./components/auth/VerifyLink";
function VerifyLinkPage() {
  return (
    <div>
      <VerifyLink />
    </div>
  );
}

export default VerifyLinkPage;
