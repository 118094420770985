import React, { useState, useEffect } from "react";
import investSvg from "../../../assets/images/invest.svg";
import debtBag from "../../../assets/images/debtBag.svg";
import savingsSvg from "../../../assets/images/savings.svg";
import savingPic from "../../../assets/images/saving.png";
import needsPic from "../../../assets/images/needs.png";
import wantsPic from "../../../assets/images/wants.png";
import { useGetProfileQuery } from "../settings/profileSlice";
import { useGetIncomesByDateQuery } from "../accounts/incomeSlice";
import { useGetExpensesByDateAndCategoryQuery } from "./expenseSlice";
import { useSelector } from "react-redux";
import { Flex, Progress } from "antd";
import Loader from "../modals/Loader";
function FundingCategory() {
  const currentDate = useSelector((state) => state.date);

  const { data, isLoading, isSuccess, isFetching, refetch, isError, error } =
    useGetProfileQuery({
      refetchOnMountOrArgChange: true,
    });
  const { data: incomesData, isLoading: loading } = useGetIncomesByDateQuery(
    currentDate,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: expensesData, isLoadingExpense } =
    useGetExpensesByDateAndCategoryQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });

  const [recommendedData, setRecommendedData] = useState([]);
  const [myPlanData, setMyPlanData] = useState([]);

  useEffect(() => {
    if (expensesData && incomesData) {
      const needsTotal = expensesData[0]
        ? expensesData[0].expenses.reduce(
            (sum, expense) => sum + parseInt(expense.amount.available),
            0
          )
        : 0;
      const wantsTotal = expensesData[1]
        ? expensesData[1].expenses.reduce(
            (sum, expense) => sum + parseInt(expense.amount.available),
            0
          )
        : 0;
      const incomesTotal = incomesData
        ? incomesData.reduce((sum, income) => sum + parseInt(income.amount), 0)
        : 0;
      console.log(incomesTotal);
      setRecommendedData([
        (incomesTotal * data?.budgetFormula[0]) / 10,
        (incomesTotal * data?.budgetFormula[1]) / 10,
        (incomesTotal * data?.budgetFormula[2]) / 10,
      ]);
      setMyPlanData([
        needsTotal,
        wantsTotal,
        (incomesTotal * data?.budgetFormula[2]) / 10,
      ]);
    }
  }, [expensesData, incomesData, data]);

  return (
    <div className=" grid grid-cols-2 md:grid-cols-4  md:justify-normal justify-between pt-4 md:gap-x-6 gap-y-4 gap-x-4  md:px-4 md:text-md  text-sm px-2">
      <div
        className={`   px-2 py-1 ${
          recommendedData[1] -
            myPlanData[1] +
            (recommendedData[0] - myPlanData[0]) <
          0
            ? "bg-red-500"
            : "bg-[#FFE2E6]"
        }   md:h-[140px]     rounded-lg `}
      >
        <div className=" flex flex-col  md:h-[140px]  p-2 justify-between  ">
          <div className="  ">
            <img src={savingsSvg} className=" md:w-12 w-6 md:h-12 h-6" alt="" />
          </div>
          <div className=" font-semibold text-black">
            <div className=" font-semibold ">Available Amount</div>
            <Progress
              percent={parseInt(
                ((recommendedData[1] -
                  myPlanData[1] +
                  (recommendedData[0] - myPlanData[0])) /
                  (recommendedData[0] + recommendedData[1])) *
                  100
              )}
              strokeColor={"green"}
              trailColor="#E5E7EB"
            />
            {!isLoading && (
              <div className="">
                {data?.currency}{" "}
                {recommendedData[1] -
                  myPlanData[1] +
                  (recommendedData[0] - myPlanData[0])}{" "}
                remaining
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={` px-2 py-1 ${
          recommendedData[0] - myPlanData[0] < 0 ? "bg-red-500" : "bg-[#60A5FA]"
        }   md:h-[140px]   rounded-lg`}
      >
        <div className=" flex flex-col  md:h-[140px] p-2 justify-between ">
          <div className=" flex justify-between items-start">
            <img src={needsPic} className=" md:w-12 md:h-12 w-6 h-6" alt="" />
            {!isLoading && (
              <div className=" bg-white tex-sm p-0.5 rounded-md">
                {data?.budgetFormula[0] * 10}%
              </div>
            )}
          </div>
          <div className="  text-black font-semibold ">
            <div className=" font-semibold  ">Needs</div>
            <Progress
              percent={parseInt((myPlanData[0] / recommendedData[0]) * 100)}
              strokeColor={"green"}
              trailColor="#E5E7EB"
            />
            {!isLoading && (
              <div className=" text-sm flex justify-between ">
                <div className="">
                  {" "}
                  {myPlanData[0]} of {recommendedData[0]} {data?.currency}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`   px-2 py-1 ${
          recommendedData[1] - myPlanData[1] < 0
            ? "bg-red-500"
            : "bg-[#1e3b8a6a]"
        }  md:h-[140px]   rounded-lg `}
      >
        <div className=" flex flex-col  md:h-[140px] p-2 justify-between ">
          <div className="flex justify-between items-start">
            <img src={wantsPic} className=" md:w-12 md:h-12 w-6 h-6" alt="" />
            {!isLoading && (
              <div className=" bg-white tex-sm p-0.5 rounded-md">
                {data?.budgetFormula[1] * 10}%
              </div>
            )}
          </div>
          <div className=" font-semibold text-white ">
            <div className=" font-semibold ">Wants</div>
            <Progress
              percent={parseInt((myPlanData[1] / recommendedData[1]) * 100)}
              strokeColor={"green"}
              trailColor="#E5E7EB"
            />
            {!isLoading && (
              <div className=" text-sm flex justify-between ">
                <div className="">
                  {" "}
                  {myPlanData[1]} of {recommendedData[1]} {data?.currency}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="   px-2 py-1 bg-[#fbafba]  md:h-[140px]   rounded-lg ">
        <div className=" flex flex-col  md:h-[140px] p-2 justify-between ">
          <div className="flex justify-between items-start">
            <img src={savingPic} className=" md:w-12 md:h-12 w-6 h-6" alt="" />
            {!isLoading && (
              <div className=" bg-white tex-sm p-0.5 rounded-md">
                {data?.budgetFormula[2] * 10}%
              </div>
            )}
          </div>
          <div className=" font-semibold text-black ">
            <div className=" font-semibold ">Savings</div>

            {!isLoading && (
              <div className="">
                {data?.currency} {recommendedData[2]}
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

export default FundingCategory;
