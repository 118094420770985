import React from "react";
import userPic from "../../assets/images/user.png";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useGetProfileQuery } from "./settings/profileSlice";
import logo from "../../assets/images/logo.png";
import darkLogo from "../../assets/images/darkLogo.png";
import { useLogoutUserMutation, useDeleteUserMutation } from "./auth/userSlice";
import { useNavigate } from "react-router-dom";

function MobileHeader() {
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();
  const [logoutUser, { isLoading: loading, error: errorMsg }] =
    useLogoutUserMutation();
  const [deleteUser, { isLoading: delLoading, error: errorDelMsg }] =
    useDeleteUserMutation();
  const logout = async () => {
    try {
      const res = await logoutUser().unwrap();
      window.location.href = "/login";

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAccount = async () => {
    try {
      const res = await deleteUser().unwrap();
      window.location.href = "/login";

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="  ">
        <img src={logo} alt="" className="w-50 h-12 rounded-lg" />
      </div>

      <div className=" flex justify-between w-full px-2 items-center pt-4">
        <div className="   text-gray-800 font-bold">Dashboard</div>

        <div className=" flex items-center space-x-2 ">
          {/* <div className=" text-green-500">
          <MdOutlineNotificationsActive className="" />
        </div> */}
          <div className="">
            <img src={userPic} alt="" className=" h-3 w-3 " />
          </div>
          <div className="">
            <div className="   text-gray-900 ">
              {profileData?.email?.split("@")[0]}
            </div>
            <div className=" text-xs  text-gray-600">Admin</div>
          </div>
          <div className=" p-1">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton
                  className={
                    "inline-flex w-full justify-center items-center gap-x-0.5 rounded-md  py-2   text-gray-100 "
                  }
                >
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={"-mr-1 h-5 w-5  text-gray-600"}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem onClick={logout}>
                    <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer">
                      Logout
                    </div>
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem onClick={deleteAccount}>
                    <div className="block px-4 py-2 text-sm text-red-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer">
                      {!delLoading ? "Delete Account" : "Deleting"}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
