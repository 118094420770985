import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNavigation } from "./reportSlice";
function Header() {
  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.report.navigation);

  return (
    <div className=" flex space-x-4 pt-4 px-4  text-gray-900">
      <div
        className={
          navigation == "Expenditure"
            ? " md:text-lg font-semibold cursor-pointer border-b-2 pb-1 border-blue-600"
            : "md:text-lg font-semibold cursor-pointer pb-1 "
        }
        onClick={() => dispatch(setNavigation("Expenditure"))}
      >
        Expenditure
      </div>
      <div
        className={
          navigation == "Net Worth"
            ? " md:text-lg font-semibold cursor-pointer border-b-2 pb-1 border-blue-600"
            : "md:text-lg font-semibold cursor-pointer pb-1"
        }
        onClick={() => dispatch(setNavigation("Net Worth"))}
      >
        Net Worth
      </div>
    </div>
  );
}

export default Header;
