// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCu1sibgs5TgV4zDQguydlLmO2LWSYk-as",
  authDomain: "budget-e6061.firebaseapp.com",
  projectId: "budget-e6061",
  storageBucket: "budget-e6061.appspot.com",
  messagingSenderId: "131294259423",
  appId: "1:131294259423:web:377b9f027c6a711ce0e16a",
  measurementId: "G-9S957QP4LL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(initializeApp(firebaseConfig));

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  // const storeUser = userStore((state) => state.setUser);

  try {
    const result = await signInWithPopup(auth, provider);
    console.log(result);
  } catch (error) {
    console.log(error);
  }
};
export const signIn = async ({ email, password }) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);

    return result;
  } catch (error) {
    const regex = /\/(.*?)\)/;
    const match = error.message.match(regex);
    if (match) {
      const extractedText = match[1];
      return { error: extractedText };
    }
  }
};
export const signUpWithEmailAndPassword = async ({ email, password }) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);

    console.log(result);
    return result;
  } catch (error) {
    // console.log(error.message);
    const regex = /\/(.*?)\)/;
    const match = error.message.match(regex);
    if (match) {
      const extractedText = match[1];
      return { error: extractedText };
    }
  }
};
export const Logout = async () => {
  try {
    const res = await signOut(auth);
    return res;
  }
   catch (error) {
    return error;
  }
};
