import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
  inDebt: undefined,
  debtPlan: undefined,
  budgetPlan: undefined,
};

export const onBoardingSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    inDebted: (state) => {
      state.step += 1;
      state.inDebt = true;
    },
    notInDebt: (state) => {
      state.step += 1;
      state.inDebt = false;
    },
    setDebtPlan: (state, action) => {
      state.step += 1;
      state.debtPlan = action.payload;
    },
    setBudgetPlan: (state, action) => {
      state.step += 1;
      state.budgetPlan = action.payload;
    },
    goBack: (state) => {
      state.step -= 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { inDebted, notInDebt, goBack } = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
