import { createSlice } from "@reduxjs/toolkit";
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
const initialState = {
  year: currentYear,
  month: currentMonth,
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.year = parseInt(action.payload.year);
      state.month = parseInt(action.payload.month);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDate } = dateSlice.actions;

export default dateSlice.reducer;
