import { apiSlice } from "../../../features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        return {
          url: "/user",
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (responseData) => {
        return responseData;
      },

      providesTags: (result, error, arg) => {
        if (!result) {
          return []; // No tags to provide if there is no result
        }
        const { userId } = result;
        return [{ type: "Profile", userId }];
      },
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `/user`,
        method: "PUT",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        if (!arg) {
          return []; // No tags to provide if there is no result
        }
        const { userId } = arg;
        return [{ type: "Profile", userId }];
      },
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation } =
  extendedApiSlice;
// returns the query result object
