import { apiSlice } from "../../../features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIncomes: builder.query({
      query: () => {
        return {
          url: "/income",
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (responseData) => {
        return responseData.incomes;
      },
      providesTags: (result, error, arg) => {
        return [
          { type: "Income", id: "LIST" },
          result.map(({ incomeId }) => {
            return { type: "Income", incomeId };
          }),
        ];
      },
    }),
    getIncomesByDate: builder.query({
      query: (date) => {
        return {
          url: "/income/date",
          method: "POST",
          body: date,
          credentials: "include",
        };
      },
      transformResponse: (responseData) => {
        return responseData;
      },
      providesTags: (result, error, arg) => {
        return [
          { type: "Income", id: "LIST" },
          result?.map(({ incomeId }) => {
            return { type: "Income", incomeId };
          }),
        ];
      },
    }),
    addIncome: builder.mutation({
      query: (data) => ({
        url: `/income/create`,
        method: "POST",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, { id: incomeId }) => {
        console.log("id", incomeId);
        return [{ type: "Income", incomeId }, { type: "Profile" }];
      },
    }),
    editIncome: builder.mutation({
      query: (data) => ({
        url: `/income/update`,
        method: "PUT",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, { incomeId }) => {
        console.log("id", incomeId);
        return [{ type: "Income", incomeId }, { type: "Profile" }];
      },
    }),
    deleteIncome: builder.mutation({
      query: (data) => ({
        url: `/income/delete`,
        method: "PUT",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, { id: incomeId }) => {
        console.log("id", incomeId);
        return [{ type: "Income", incomeId }, { type: "Profile" }];
      },
    }),
  }),
});

export const {
  useGetIncomesQuery,
  useAddIncomeMutation,
  useDeleteIncomeMutation,
  useEditIncomeMutation,
  useGetIncomesByDateQuery,
} = extendedApiSlice;
