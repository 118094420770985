import React, { useEffect, useState } from "react";
import SideBanner from "../SideBanner";
import Spinner from "../utils/Spinner";
import { useSendVerificationEmailMutation } from "./userSlice";
import { useVerifyEmailMutation } from "./userSlice";

import { useGetProfileQuery } from "../settings/profileSlice";
import logo from "../../../assets/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VerifyEmail() {
  const [loading, setLoading] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [counter, setCounter] = useState(120); // Start with 60 seconds
  const [otp, setOtp] = useState(); // Start with 60 seconds

  const handleSendOtp = () => {
    // Your logic to send OTP
    console.log("OTP sent");

    // Disable the button and start the countdown
    setIsDisabled(true);
    setCounter(60); // Reset counter to 60 seconds

    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 1) {
          clearInterval(interval); // Clear interval when counter reaches 0
          setIsDisabled(false); // Re-enable the button
        }
        return prevCounter - 1;
      });
    }, 1000); // Run every second
  };

  const [
    sendVerificationEmail,
    { isLoading: loadingEmail, error: emailErrorMsg },
  ] = useSendVerificationEmailMutation();

  const [
    verifyEmail,
    { isLoading: loadingVerifyEmail, error: emailVerifyErrorMsg },
  ] = useVerifyEmailMutation();
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (profileData?.emailVerified) {
      window.location.href = "/dashboard";
    }
  }, [profileData]);
  const sendVerifyEmail = async () => {
    try {
      setLoading(true);

      const resEmail = await sendVerificationEmail().unwrap();
      console.log(resEmail);
      toast.success("Verification email sent successfully!");

      setLoading(false);
      setIsDisabled(true);
      setCounter(60); // Reset counter to 60 seconds

      const interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1) {
            clearInterval(interval); // Clear interval when counter reaches 0
            setIsDisabled(false); // Re-enable the button
          }
          return prevCounter - 1;
        });
      }, 1000); // Run every second
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const sendVerifyingEmail = async () => {
    try {
      const resEmail = await verifyEmail(otp).unwrap();
      console.log(resEmail);
      toast.success("Email Verified!");
      window.location.href = "/dashboard";
    } catch (error) {
      toast.error(error?.data?.message);
      console.log(error);
    }
  };
  return (
    <div className=" flex  h-screen ">
      <div className="md:w-1/2 md:block hidden   h-full overflow-y-scroll">
        <SideBanner />
      </div>
      <div className=" md:w-1/2 w-full bg-white  h-full overflow-y-scroll  px-4 md:py-10 py-8">
        <div className=" grid md:grid-cols-2 grid-cols-1   ">
          <div className="flex items-center md:justify-normal  justify-center ">
            <img src={logo} className="w-60 h-16 rounded-lg" alt="" />
          </div>
        </div>
        <div className="  w-full md:px-10 px-2 pt-6 ">
          <div className=" text-center text-xl font-bold ">Verify Email</div>

          {isDisabled && (
            <div className=" text-center">
              A verification email has been sent to you. Please check your
              email.
            </div>
          )}
          {isDisabled && (
            <div className="">
              <div className="block text-sm font-medium  text-gray-800">
                Verification Code
              </div>
              <input
                type="number"
                id="otp"
                name="otp"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
                autoComplete="otp"
                placeholder="0000"
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          )}
          <div className=" flex justify-between space-x-8 items-center pt-4 w-full">
            <button
              className=" bg-[#13143E] text-white w-full py-1 rounded-md"
              onClick={sendVerifyEmail}
              disabled={isDisabled}
            >
              {loading ? (
                <Spinner />
              ) : isDisabled ? (
                `Resend Code in ${counter}s`
              ) : (
                "Send Verification Code"
              )}
            </button>
            {isDisabled && (
              <button
                className=" bg-[#13143E] text-white w-full py-1 rounded-md"
                onClick={sendVerifyingEmail}
              >
                {loadingVerifyEmail ? <Spinner /> : "Verify Email"}
              </button>
            )}
          </div>
          <div className=" flex  justify-center items-center pt-4 w-full"></div>
          <div className="pt-8 flex items-center justify-center">
            <div className=" text-sm text-gray-500">
              Anthonomics 2024 | All rights reserved
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default VerifyEmail;
