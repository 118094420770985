import Spinner from "../utils/Spinner";

function Loader({ closeHandler }) {
  return (
    <>
      <div
        className="fixed w-full inset-0 z-10 overflow-hidden flex justify-center  items-center animated fadeIn faster"
        style={{ background: "rgba(0,0,0,.5)" }}
      >
        <div className="shadow-xl modal-container  text-white w-full md:w-3/5 lg:w-3/5 xl:w-1/3 mx-auto rounded-t-lg md:rounded-lg z-100 overflow-y-auto max-h-full">
          <Spinner />
        </div>
      </div>
    </>
  );
}

export default Loader;
