import React from "react";
import Login from "./components/auth/Login";

function LoginPage() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginPage;
