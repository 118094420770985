import React from "react";
import johnPic from "../assets/images/john.jpg";
import janePic from "../assets/images/jane.jpg";
function UserStory() {
  return (
    <div className="md:p-20 p-10">
      <div className=" text-4xl font-bold  md:py-10 py-5">User Stories</div>
      <div className=" grid md:grid-cols-4 grid-cols-1 gap-4">
        <div className=" bg-white p-2 shadow-xl">
          <div className=" flex space-x-4 items-center">
            <div className=" font-semibold text-lg ">Peter M.</div>
          </div>
          <div className=" text-gray-600 pt-2 ">
            Anthonomics has completely transformed how I manage my money. The
            app is intuitive and easy to use, making budgeting a breeze. I love
            being able to track my expenses and investments in real time, and
            it’s been a game-changer for my financial planning.
          </div>
        </div>
        <div className=" bg-white p-2 shadow-xl">
          <div className=" flex space-x-4 items-center">
            <div className=" font-semibold text-lg ">Jennifer O.</div>
          </div>
          <div className=" text-gray-600 pt-2 ">
            I never realized how much peace of mind I could have from simply
            getting my finances organized. Anthonomics makes it so easy to set
            up a budget that works for me and stick to it. Plus, the fact that
            it's free is just icing on the cake!
          </div>
        </div>

        <div className=" bg-white p-2 shadow-xl">
          <div className=" flex space-x-4 items-center">
            <div className=" font-semibold text-lg ">Oluwatosin C.</div>
          </div>
          <div className=" text-gray-600 pt-2 ">
            What I appreciate most about Anthonomics is how it simplifies
            everything. From setting savings goals to tracking daily expenses,
            it’s all so straightforward. The ability to suggest new features is
            a great touch—I feel like the app truly values its users.
          </div>
        </div>
        <div className=" bg-white p-2 shadow-xl">
          <div className=" flex space-x-4 items-center">
            <div className=" font-semibold text-lg ">Yvonne N.</div>
          </div>
          <div className=" text-gray-600 pt-2 ">
            Since I started using Anthonomics, I've gained so much control over
            my spending. The app’s insights have helped me make better financial
            decisions, and I love that I can customize my budget to fit my
            lifestyle. It’s a must-have for anyone serious about their finances.
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserStory;
