import React, { useEffect, useState } from "react";
import moneyBagSvg from "../assets/images/money.svg";
import SideBanner from "./components/SideBanner";
import { Radio } from "antd";

function Survey() {
  const [value, setValue] = useState(1);
  const [debt, setDebt] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const onDebtChange = (e) => {
    console.log("radio checked", e.target.value);
    setDebt(e.target.value);
  };
  return (
    <div className=" flex  h-screen ">
      <div className=" w-1/2 bg-[#20203C] h-full  p-4">
        <div className=" flex justify-center items-center">
          <div className=" flex items-center space-x-2">
            <div className=" flex justify-center items-center">
              <img src={moneyBagSvg} className=" w-8 h-8" alt="" />
            </div>
          </div>
        </div>
        <div className=" text-xl font-semibold pt-4 text-center text-white">
          Please Complete the survey below
        </div>
        <ol className=" list-decimal p-4">
          <li className=" pt-4 text-white text-lg ">
            <div className="">Are you struggling with debt?</div>
            <div className=" pt-2">
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1} className="text-white">
                  Yes
                </Radio>
                <Radio value={2} className="text-white">
                  No
                </Radio>
              </Radio.Group>
            </div>
          </li>
          <li className=" pt-4 text-white text-lg ">
            <div className="">
              Would you like us to help you come out of debt?
            </div>
            <div className=" pt-2">
              <Radio.Group onChange={onDebtChange} value={debt}>
                <Radio value={1} className="text-white">
                  Yes
                </Radio>
                <Radio value={2} className="text-white">
                  No
                </Radio>
              </Radio.Group>
            </div>
          </li>
        </ol>
      </div>

      <div className=" w-1/2 h-full">
        <SideBanner />
      </div>
    </div>
  );
}

export default Survey;
