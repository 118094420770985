import React from "react";
import Dashboard from "./Dashboard";
import ReportHeader from "./components/report/Header";
import Category from "./components/report/Category";
import Charts from "./components/report/Charts";
import ReportDetails from "./components/report/ReportDetails";
import MobileHeader from "./components/MobileHeader";
import Header from "./components/budget/Header";
function Report() {
  return (
    <Dashboard>
      <div className="  w-full">
        <div className=" md:hidden block">
          <MobileHeader />
        </div>
        <div className=" hidden md:block">
          <Header />
        </div>
        <ReportHeader />
        <Category />
        <div className=" grid grid-cols-6 gap-y-2 h-full ">
          <div className=" md:col-span-4 col-span-6  md:h-full ">
            <Charts />
       
          </div>
          <div className=" md:col-span-2 col-span-6  md:h-full">
            <ReportDetails />
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default Report;
