import React from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { Input } from "antd";
const { Search } = Input;

function Footer() {
  return (
    <div className="  bg-[#19223C] px-4 py-4">
      {/* <div className=" text-2xl font-bold text-white">
            Dollar Sense
        </div>
<div className=" flex justify-between pt-4">
<div className=''>
<Search placeholder="input search text" className=' bg-[#2C396A]' onSearch={()=>{}} style={{ width: 250, backgroundColor:'#2C396A'}} />
<div className=' text-[#829CF8] flex space-x-4 justify-between pt-4'>
<div className="">
<FaFacebook className=" text-2xl"/>
</div>
<div className="">
<FaInstagram className="  text-2xl"/>
</div>
<div className="">
<FaTiktok className="  text-2xl"/>
</div>
<div className="">
<FaYoutube className="  text-2xl"/>
</div>
<div className="">
< FaXTwitter className="  text-2xl"/>
</div>
    </div>
    <div className=' text-[#829CF8] text-xs pt-4 '>
    Copyright © 2024, YNAB. All Rights Reserved.
    </div>
</div>
<div className=" text-[#829CF8] flex flex-col  space-y-4 ">
    <div className=" text-white font-bold">
    Company
    </div>
    <div className="">
        About 
    </div>
    <div className="">
        Careers
    </div>
    <div className="">
        Press
    </div>
    <div className="">
    YNAB: The Book
    </div>
</div>
<div className=" text-[#829CF8] flex flex-col space-y-4 ">
    <div className=" text-white font-bold">
    Programs

    </div>
    <div className="">
    YNAB for the Workplace

    </div>
    <div className="">
    YNAB for College Students

    </div>
    <div className="">
    Certified Coaching

    </div>
    <div className="">
    YNAB For Good
    </div>
</div>
<div className=" text-[#829CF8] flex flex-col space-y-4 ">
    <div className=" text-white font-bold">
    App

    </div>
    <div className="">
    Status

    </div>
    <div className="">
    What's New

    </div>
    <div className="">
    API

    </div>
    <div className="">
    Cancellation

    </div>
    <div className="">
    Help Center

    </div>
</div>
<div className=" text-[#829CF8] flex flex-col space-y-4 ">
    <div className=" text-white font-bold">
    Legal

    </div>
    <div className="">
    Terms

    </div>
    <div className="">
    Security

    </div>
    <div className="">
    Privacy Policy

    </div>
    <div className="">
    California Privacy Policy

    </div>
    <div className="">
    Your Privacy Choices


    </div>
    <div className="">
    TrueLayer Agent Disclosure


    </div>
</div>
</div> */}
      <div className=" text-center text-white">
        © 2024 Anthonomics, All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
