import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { color } from "chart.js/helpers";
import { useGetExpensesByDateAndCategoryQuery } from "../budget/expenseSlice";
import { useGetIncomesByDateQuery } from "../accounts/incomeSlice";
import { useGetProfileQuery } from "../settings/profileSlice";
import { useSelector } from "react-redux";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

function BarChart() {
  const currentDate = useSelector((state) => state.date);
  const { data: expensesData, isLoading } =
    useGetExpensesByDateAndCategoryQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });

  const { data: incomesData, isLoading: loading } = useGetIncomesByDateQuery(
    currentDate,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  console.log(incomesData);
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const [recommendedData, setRecommendedData] = useState([]);
  const [myPlanData, setMyPlanData] = useState([]);

  useEffect(() => {
    if (expensesData && incomesData) {
      const needsTotal = expensesData[0]
        ? expensesData[0].expenses.reduce(
            (sum, expense) => sum + parseInt(expense.amount.available),
            0
          )
        : 0;
      const wantsTotal = expensesData[1]
        ? expensesData[1].expenses.reduce(
            (sum, expense) => sum + parseInt(expense.amount.available),
            0
          )
        : 0;
      const incomesTotal = incomesData
        ? incomesData.reduce((sum, income) => sum + parseInt(income.amount), 0)
        : 0;
      console.log(incomesTotal);
      setRecommendedData([
        (incomesTotal * profileData?.budgetFormula[0]) / 10,
        (incomesTotal * profileData?.budgetFormula[1]) / 10,
        (incomesTotal * profileData?.budgetFormula[2]) / 10,
      ]);
      setMyPlanData([
        needsTotal,
        wantsTotal,
        (incomesTotal * profileData?.budgetFormula[2]) / 10,
      ]);
    }
  }, [expensesData, incomesData, profileData]);
  // console.log(myPlanData);
  const data = {
    labels: ["Needs", "Wants", "Savings"],
    datasets: [
      {
        label: "Recommended Plan",
        backgroundColor: "rgba(251, 175, 186, 1)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: recommendedData,
      },

      {
        label: "My Plan",
        backgroundColor: "rgba(96, 165, 250, 1)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "rgba(96, 165, 250, 1)",
        hoverBorderColor: "rgba(255,99,132,1)",
        color: "rgba(0,0,0,1)",
        data: myPlanData,
      },
    ],
  };
  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    type: "bar",
    //   scales: {
    //     xAxes: [{
    //         stacked: true
    //     }],
    //     yAxes: [{
    //         stacked: true
    //     }]
    // }
  };
  return <Bar data={data} options={options} />;
}

export default BarChart;
