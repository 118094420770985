import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import {
  useGetIncomesByDateQuery,
  useDeleteIncomeMutation,
  useEditIncomeMutation,
} from "./incomeSlice";
import UpdateIncomeModal from "../modals/UpdateIncomeModal";
import { useSelector } from "react-redux";
const BalanceTable = () => {
  const [showUpdateModal, setShowUpdateModal] = useState();
  const [income, setIncome] = useState();
  const [amount, setAmount] = useState();
  const [incId, setIncId] = useState();
  const currentDate = useSelector((state) => state.date);

  const { data, isLoading, isSuccess, isFetching, refetch, isError, error } =
    useGetIncomesByDateQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });
  const [editIncome, { isLoading: loadingIncome, error: incomeErrorMsg }] =
    useEditIncomeMutation();
  const [
    deleteIncome,
    { isLoading: loadingDelIncome, error: incomeDelErrorMsg },
  ] = useDeleteIncomeMutation();
  const deleteOneIncome = async (incomeId) => {
    try {
      const res = await deleteIncome({
        incomeId: incomeId,
      }).unwrap();
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const editOneIncome = async (incomeId) => {
    const res = await editIncome({
      incomeId: incId,
      updatedIncome: {
        amount: amount,
        income: income,
      },
    }).unwrap();
    setShowUpdateModal(false);
    console.log(res);
  };
  console.log("Incomes data:", data);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <a>{new Date(text).toDateString()}</a>,
    },
    {
      title: "Source of Money",
      dataIndex: "income",
      key: "income",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Action",
      dataIndex: "incomeId",
      key: "incomeId",

      render: (incomeId) => (
        <Space size="middle">
          <div
            onClick={() => {
              setIncId(incomeId);
              const editableIncome = data.find(
                (inc) => inc.incomeId == incomeId
              );

              if (editableIncome) {
                setIncome(editableIncome.income);
                setAmount(editableIncome.amount);
              }
              setShowUpdateModal(true);
            }}
            className=" cursor-pointer text-gray-500"
          >
            Edit
          </div>
          <div
            onClick={() => deleteOneIncome(incomeId)}
            className=" cursor-pointer text-red-400"
          >
            Delete
          </div>
        </Space>
      ),
    },
  ];
  return (
    <div className="overflow-x-scroll md:w-5/6  w-full -z-10 ">
      <Table
        scroll={{ x: 800, y: 500 }}
        columns={columns}
        dataSource={data}
        className=""
      />
      {showUpdateModal && (
        <UpdateIncomeModal
          closeHandler={() => setShowUpdateModal(false)}
          editOneIncome={editOneIncome}
          setIncome={setIncome}
          setAmount={setAmount}
          income={income}
          amount={amount}
          loading={loadingIncome}
        />
      )}
    </div>
  );
};
export default BalanceTable;
