import React, { useState, useEffect } from "react";
import { IoIosAddCircle } from "react-icons/io";
import spending from "../../../assets/images/spending.png";

import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import UpdateUtilityModal from "../modals/UpdateUtilityModal";
import UtilityModal from "../modals/UtilityModal";
import { IoIosAddCircleOutline } from "react-icons/io";
import DatePicker from "../buttons/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  useGetExpensesQuery,
  useGetExpensesByDateAndCategoryQuery,
  useAddExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
} from "./expenseSlice";
import { useGetIncomesByDateQuery } from "../accounts/incomeSlice";
import { useGetProfileQuery } from "../settings/profileSlice";
import Moment from "react-moment";
import { useSelector } from "react-redux";

function Category() {
  const currentDate = useSelector((state) => state.date);
  const date = new Date(currentDate.year, currentDate.month - 1);
  const monthName = date.toLocaleString("default", { month: "long" });
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  return (
    <div className=" px-3  bg-gray-100">
      <div className=" flex justify-between items-center">
        <div className=" flex space-x-2 items-center py-3">
          <div className="">
            <DatePicker />
          </div>
          <div className=" py-1 px-4 text-sm rounded-md bg-[#5A82E3] text-white ">
            {monthName} {currentDate.year}
          </div>
        </div>
        <div className=" bg-red-500 py-0.5 text-sm px-4 text-white rounded-md ">
          Debt {profileData?.debt}
        </div>
      </div>
      <div className="  grid grid-cols-10 py-2 border-t border-gray-400 px-4">
        <div className=" col-span-3">
          <div className=" flex md:space-x-2 space-x-1 items-center">
            <div className="uppercase text-sm   text-gray-900 ">Category</div>
            {/* <div className=" text-white">
              <IoIosAddCircle className="" />
            </div> */}
          </div>
        </div>
      </div>
      <CategoryTable />
    </div>
  );
}

export default Category;

const CategoryTable = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUtility, setShowUtility] = useState(false);
  const [expenseName, setExpenseName] = useState();
  const [categoryName, setCategoryName] = useState();
  const [categoryId, setCategoryId] = useState();
  const [expenseId, setExpenseId] = useState();
  const [requiredAmount, setRequiredAmount] = useState(0);
  const [availableAmount, setAvailableAmount] = useState(0);
  const currentDate = useSelector((state) => state.date);
  const [recommendedData, setRecommendedData] = useState([]);
  const { data, isLoading, isSuccess, isFetching, refetch, isError, error } =
    useGetExpensesByDateAndCategoryQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });
  console.log(data);
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const [addExpense, { isLoading: loading, error: errorMsg }] =
    useAddExpenseMutation();
  const [updateExpense, { isLoading: loadingExpense, error: expenseErrorMsg }] =
    useUpdateExpenseMutation();
  const [
    deleteExpense,
    { isLoading: loadingDelExpense, error: expenseDelErrorMsg },
  ] = useDeleteExpenseMutation();
  const { data: incomesData, isLoading: loadingIncome } =
    useGetIncomesByDateQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });
  const addOneExpense = async () => {
    try {
      console.log(expenseName);
      console.log(categoryId);
      console.log(categoryName);
      const res = await addExpense({
        name: expenseName,
        categoryId: categoryId,
        categoryName: categoryName,
        available: availableAmount,
      }).unwrap();

      console.log(res);
      toast.success("Expense added successfully!");

      setShowUtility(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const updateOneExpense = async () => {
    try {
      const res = await updateExpense({
        expenseId: expenseId,
        amount: {
          required: requiredAmount,
          available: availableAmount,
        },
      }).unwrap();
      console.log(res);
      toast.success("Expense updated successfully!");

      setShowUpdate(false);
      setRequiredAmount(0);
      setAvailableAmount(0);
    } catch (error) {
      console.log(error);
      setRequiredAmount(0);
      setAvailableAmount(0);
      toast.error("Something went wrong!");
    }
  };
  const deleteOneExpense = async (newExpenseId) => {
    try {
      const res = await deleteExpense({
        expenseId: newExpenseId,
      }).unwrap();
      toast.success("Expense deleted successfully!");
      console.log(res);
    } catch (error) {
      toast.error("Something went wrong!");

      console.log(error);
    }
  };
  useEffect(() => {
    if (profileData && incomesData) {
      const incomesTotal = incomesData
        ? incomesData.reduce((sum, income) => sum + parseInt(income.amount), 0)
        : 0;
      console.log(incomesTotal);
      setRecommendedData([
        (incomesTotal * profileData?.budgetFormula[0]) / 10,
        (incomesTotal * profileData?.budgetFormula[1]) / 10,
        (incomesTotal * profileData?.budgetFormula[2]) / 10,
      ]);
    }
  }, [incomesData, profileData]);
  console.log("recdata", 0 / 0);
  return (
    <div className=" w-full px-2 ">
      {data &&
        !isLoading &&
        data.map((item, index) => (
          <div className="w-full">
            <div className="  grid grid-cols-10 py-2 border-t border-gray-400  bg-gray-200 px-4">
              <div className=" col-span-3">
                <div className=" flex space-x-2 items-center">
                  <div className="uppercase text-sm text-black font-semibold pb-1 ">
                    {item?.categoryName}
                  </div>
                  <div
                    className=" cursor-pointer p-1 "
                    onClick={() => {
                      setCategoryName(item?.categoryName);
                      setCategoryId(item?.categoryId);
                      setShowUtility(true);
                    }}
                  >
                    <IoIosAddCircleOutline className="" />
                  </div>
                </div>
              </div>

              <div className=" col-span-3">
                <div className=" uppercase text-sm text-black ">Assigned</div>
              </div>
              <div className=" col-span-3">
                <div className=" uppercase text-sm text-black ">% Spent</div>
              </div>
            </div>
            {item.expenses.map((expense) => (
              <div className=" grid grid-cols-10 py-1 items-center border-b border-gray-400  px-4">
                <div className=" col-span-3">
                  <div className="">
                    <div className=" flex space-x-2 items-center">
                      <div className="">
                        <div className=" flex space-x-2 items-center">
                          <img src={spending} className=" w-5 h-5" alt="" />
                          <div className=" text-sm font-semibold   text-gray-900 ">
                            {expense?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" col-span-3">
                  <div className=" uppercase text-sm  text-gray-900 ">
                    {profileData?.currency} {expense?.amount?.available}
                  </div>
                </div>
                <div className=" col-span-3">
                  <div className=" uppercase text-sm  text-gray-900 ">
                    {isNaN(
                      parseInt(
                        (expense?.amount?.available / recommendedData[0]) * 100
                      )
                    )
                      ? 0
                      : parseInt(
                          (expense?.amount?.available / recommendedData[0]) *
                            100
                        )}{" "}
                    %
                  </div>
                </div>
                <div className=" col-span-1   flex justify-center py-1">
                  <div className=" flex items-center space-x-2  ">
                    <div
                      className=" text-blue-600 cursor-pointer hover:bg-gray-300 rounded-full p-1 "
                      onClick={() => {
                        setExpenseId(expense?.expenseId);
                        setShowUpdate(true);
                      }}
                    >
                      <MdEdit className="" />
                    </div>
                    <div
                      className=" text-red-500  cursor-pointer hover:bg-gray-300 rounded-full p-1 "
                      onClick={() => deleteOneExpense(expense?.expenseId)}
                    >
                      <MdDelete className="" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className=" grid grid-cols-10 mb-2 py-1 items-center border-b border-gray-400  px-4">
              <div className=" col-span-3"></div>
              <div className=" col-span-3 text-sm ">
                {" "}
                {profileData?.currency}{" "}
                {item.expenses.reduce(
                  (sum, expense) => sum + parseInt(expense?.amount?.available),
                  0
                )}{" "}
              </div>
              <div className=" col-span-3">
                <div className="text-green-500 text-sm">
                  {isNaN(
                    parseInt(
                      (parseInt(
                        item.expenses.reduce(
                          (sum, expense) =>
                            sum + parseInt(expense?.amount?.available),
                          0
                        )
                      ) /
                        (item.categoryName == "needs"
                          ? recommendedData[0]
                          : recommendedData[1])) *
                        100
                    )
                  )
                    ? 0
                    : parseInt(
                        (parseInt(
                          item.expenses.reduce(
                            (sum, expense) =>
                              sum + parseInt(expense?.amount?.available),
                            0
                          )
                        ) /
                          (item.categoryName == "needs"
                            ? recommendedData[0]
                            : recommendedData[1])) *
                          100
                      )}{" "}
                  %
                </div>
              </div>
            </div>
          </div>
        ))}

      {/* ======== */}

      {/* <div className="  grid grid-cols-10 py-2 border-t border-gray-400 bg-gray-200 px-4">
        <div className=" col-span-3">
          <div className=" flex space-x-2 items-center">
            <div className="uppercase text-sm text-black font-semibold  ">
              Needs
            </div>
            <div
              className=" cursor-pointer p-1 "
              onClick={() => setShowUtility(true)}
            >
              <IoIosAddCircleOutline className="" />
            </div>
          </div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-black ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-black ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-black">Ksh 0.00</div>
        </div>
      </div>
      <div className=" grid grid-cols-10 py-1 items-center border-b border-gray-400  px-4">
        <div className=" col-span-3">
          <div className="">
            <div className=" flex space-x-2 items-center">
              <div className="">
                <div className=" flex space-x-2 items-center">
                  <img src={basket} className=" w-5 h-5" alt="" />
                  <div className=" text-sm font-semibold  text-white ">
                    Groceries
                  </div>
                </div>
                <Flex
                  vertical
                  gap="small"
                  style={{
                    width: 130,
                  }}
                >
                  <Progress percent={0} size="small" />
                </Flex>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white">Ksh 0.00</div>
        </div>
        <div className=" col-span-1   flex justify-center py-1">
          <div className=" flex items-center space-x-2  ">
            <div
              className=" text-blue-600 cursor-pointer hover:bg-gray-300 rounded-full p-1 "
              onClick={() => setShowUpdate(true)}
            >
              <MdEdit className="" />
            </div>
            <div className=" text-red-500  cursor-pointer hover:bg-gray-300 rounded-full p-1 ">
              <MdDelete className="" />
            </div>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-10 py-1 items-center border-b border-gray-400  px-4">
        <div className=" col-span-3">
          <div className="">
            <div className=" flex space-x-2 items-center">
              <div className="">
                <div className=" flex space-x-2 items-center">
                  <img src={car} className=" w-5 h-5" alt="" />
                  <div className=" text-sm font-semibold  text-white ">
                    Transportation
                  </div>
                </div>
                <Flex
                  vertical
                  gap="small"
                  style={{
                    width: 130,
                  }}
                >
                  <Progress percent={0} size="small" />
                </Flex>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white">Ksh 0.00</div>
        </div>
        <div className=" col-span-1   flex justify-center py-1">
          <div className=" flex items-center space-x-2  ">
            <div
              className=" text-blue-600 cursor-pointer hover:bg-gray-300 rounded-full p-1 "
              onClick={() => setShowUpdate(true)}
            >
              <MdEdit className="" />
            </div>
            <div className=" text-red-500  cursor-pointer hover:bg-gray-300 rounded-full p-1 ">
              <MdDelete className="" />
            </div>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-10 py-1 items-center border-b border-gray-400  px-4">
        <div className=" col-span-3">
          <div className="">
            <div className=" flex space-x-2 items-center">
              <div className="">
                <div className=" flex space-x-2 items-center">
                  <img src={medicine} className=" w-5 h-5" alt="" />
                  <div className=" text-sm font-semibold  text-white ">
                    Medical Expenses
                  </div>
                </div>
                <Flex
                  vertical
                  gap="small"
                  style={{
                    width: 130,
                  }}
                >
                  <Progress percent={0} size="small" />
                </Flex>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white ">Ksh 0.00</div>
        </div>
        <div className=" col-span-2">
          <div className=" uppercase text-sm text-white">Ksh 0.00</div>
        </div>
        <div className=" col-span-1   flex justify-center py-1">
          <div className=" flex items-center space-x-2  ">
            <div
              className=" text-blue-600 cursor-pointer hover:bg-gray-300 rounded-full p-1 "
              onClick={() => setShowUpdate(true)}
            >
              <MdEdit className="" />
            </div>
            <div className=" text-red-500  cursor-pointer hover:bg-gray-300 rounded-full p-1 ">
              <MdDelete className="" />
            </div>
          </div>
        </div>
      </div> */}
      {showUpdate && (
        <UpdateUtilityModal
          closeHandler={() => setShowUpdate(false)}
          setRequiredAmount={setRequiredAmount}
          setAvailableAmount={setAvailableAmount}
          requiredAmount={requiredAmount}
          availableAmount={availableAmount}
          updateOneExpense={updateOneExpense}
          loadingExpense={loadingExpense}
        />
      )}
      {showUtility && (
        <UtilityModal
          closeHandler={() => setShowUtility(false)}
          setExpenseName={setExpenseName}
          expenseName={expenseName}
          availableAmount={availableAmount}
          setAvailableAmount={setAvailableAmount}
          loading={loading}
          addOneExpense={addOneExpense}
        />
      )}
      <ToastContainer />
    </div>
  );
};
