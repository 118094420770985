import React from "react";
import { DatePicker, Space } from "antd";
import { useDispatch } from "react-redux";
import { setDate } from "./dateSlice";

const App = () => {
  const dispatch = useDispatch();

  const onChange = (date, dateString) => {
    const yearMonth = (date, dateString);
    const year = yearMonth.split("-")[0];
    const month = yearMonth.split("-")[1];
    if (year && month) {
      dispatch(
        setDate({
          year,
          month,
        })
      );
    }
  };
  return (
    <div className=" text-blue-600">
      <div className=" ">
        <DatePicker
          onChange={onChange}
          picker="month"
          className=" text-blue-600"
        />
      </div>
    </div>
  );
};
export default App;
