import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useGetProfileQuery } from "../settings/profileSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart() {
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const data = {
    labels: ["Needs", "Wants", "Savings"],
    datasets: [
      {
        label: "% of Income",
        data: profileData?.budgetFormula,
        backgroundColor: [
          "rgba(251, 175, 186, 1)",
          "rgba(96, 165, 250, 1)",
          "rgba(22, 39, 80, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(22, 39, 80, 1)",
        ],
        borderWidth: 1,
        fontColor: "white",
      },
    ],
  };
  return <Pie data={data} />;
}

export default PieChart;
