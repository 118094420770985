import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useGetProfileQuery } from "../settings/profileSlice";
import { useSelector } from "react-redux";
import { useGetIncomesByDateQuery } from "../accounts/incomeSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Salary", "Investments", "Pension", "Rental Income"],
  datasets: [
    {
      label: "% of Income",
      data: [12, 19, 3, 5],
      backgroundColor: [
        "rgba(251, 175, 186, 1)",
        "rgba(96, 165, 250, 1)",
        "rgba(255, 80, 35, 1)",
        "rgba(75, 192, 192, 0.5)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
      ],
      borderWidth: 1,
      fontColor: "white",
    },
  ],
};
function IncomeChart() {
  const [incomes, setIncomes] = useState(0);

  const currentDate = useSelector((state) => state.date);
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: incomesData, isLoading: loading } = useGetIncomesByDateQuery(
    currentDate,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  useEffect(() => {
    if (incomesData) {
      const incomesTotal = incomesData
        ? incomesData.reduce((sum, income) => sum + parseInt(income.amount), 0)
        : 0;
      setIncomes(incomesTotal);
    }
  }, [incomesData]);
  console.log(profileData);
  return (
    <div className="">
      <div className="">
        <div className=" pb-10">Net Income</div>
        <div className=" text-xl font-bold">
          {profileData?.currency} {incomes}
        </div>
      </div>
    </div>
  );
}

export default IncomeChart;
