import React from "react";
import Dashboard from "./Dashboard";
import HeaderAccounts from "./components/accounts/Header";
import Balance from "./components/accounts/Balance";
import IncomeTable from "./components/accounts/IncomeTable";
import MobileHeader from "./components/MobileHeader";
import Header from "./components/budget/Header";

function Accounts() {
  return (
    <Dashboard>
      <div className=" md:w-full w-screen   overflow-x-hidden overflow-y-scroll no-scrollbar ">
        <div className=" md:hidden block">
          <MobileHeader />
        </div>
        {/* <div className=" hidden md:block">
          <Header />
        </div> */}
        <HeaderAccounts />
        <div className=" w-full overflow-x-hidden  border-y border-gray-200 ">
          <Balance />
        </div>

        <IncomeTable />
      </div>
    </Dashboard>
  );
}

export default Accounts;
