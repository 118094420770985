import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "All Categories",
  navigation: "Expenditure",
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategory, setNavigation } = reportSlice.actions;

export default reportSlice.reducer;
