import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetExpensesByDateAndCategoryQuery } from "../budget/expenseSlice";
import { useGetProfileQuery } from "../settings/profileSlice";
function ReportDetails() {
  const currentDate = useSelector((state) => state.date);
  const date = new Date(currentDate.year, currentDate.month - 1);
  const monthName = date.toLocaleString("default", { month: "long" });
  const { data: expensesData, isLoading } =
    useGetExpensesByDateAndCategoryQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const [expenses, setExpenses] = useState();
  const [needs, setNeeds] = useState(0);
  const [wants, setWants] = useState(0);

  useEffect(() => {
    if (expensesData) {
      const needsTotal = expensesData[0]
        ? expensesData[0].expenses.reduce(
            (sum, expense) => sum + parseInt(expense.amount.available),
            0
          )
        : 0;
      setNeeds(needsTotal);
      const wantsTotal = expensesData[1]
        ? expensesData[1].expenses.reduce(
            (sum, expense) => sum + parseInt(expense.amount.available),
            0
          )
        : 0;
      setWants(wantsTotal);
      setExpenses(needsTotal + wantsTotal);
    }
  }, [expensesData]);
  return (
    <div className=" bg-gray-200 px-4 flex flex-col items-center justify-center  space-y-10  py-12">
      <div className=" text-center border-b pb-4 w-full border-gray-400 ">
        <div className=" text-lg font-bold capitalize">
          {monthName} {currentDate.year}
        </div>
        <div className=" text-sm">All categories and accounts included</div>
      </div>
      <div className=" text-center border-b pb-4 w-full border-gray-400  ">
        <div className=" text-sm">TOTAL SPENDING</div>
        <div className=" text-lg font-bold">
          {profileData?.currency} {expenses}
        </div>
        <div className="">All categories and accounts included</div>
      </div>
      {/* <div className=" text-center border-b pb-4 w-full border-gray-400  ">
        <div className=" text-sm">Average SPENDING</div>
        <div className=" text-lg font-bold"> {profileData?.currency} 0.00</div>
        <div className=""> Per month</div>
      </div> */}
      <div className=" flex flex-col space-y-2 w-full px-4">
        <div className=" grid grid-cols-2 w-full ">
          <div className=" text-sm font-semibold ">Categories</div>
          <div className="text-sm font-semibold text-center">Spending</div>
        </div>
        <div className=" grid grid-cols-2 w-full ">
          <div className=" text-sm  ">Wants</div>
          <div className="text-sm  text-center">
            {profileData?.currency} {wants}
          </div>
        </div>
        <div className=" grid grid-cols-2 w-full ">
          <div className=" text-sm  ">Needs</div>
          <div className="text-sm  text-center">
            {profileData?.currency} {needs}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDetails;
