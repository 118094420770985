import React from "react";
import spendingPic from "../assets/images/spending1.png";
import shuttlePic from "../assets/images/shuttle.png";
function Spending() {
  return (
    <div className=" flex flex-wrap bg-gray-100 md:px-20 px-8 md:py-10 py-4">
      <div className=" md:w-1/2 w-full">
        <div className="flex items-center">
          <div className=" text-5xl  text-gray-800 font-bold md:pt-20 pt-10 md:pb-10 pb-5">
            Stay On Top Of Your <span className="text-[#A2CC40]">Spending</span>
            <span>
              <img
                src={shuttlePic}
                alt="Shuttle"
                className="md:w-20 md:h-20 w-12 h-12"
              />
            </span>
          </div>
        </div>
        <div className="  leading-6 text-lg">
          Get an effortless breakdown of your finances to see where your money
          is going and how to improve. You’ll be able to see in real-time what’s
          left in each spending pot and choose a budgeting style that suits you,
          such as the 60:30:10 rule. Take control of your financial life with a
          clear and organized view of your spending.
        </div>
      </div>
      <div className=" md:w-1/2 w-full  flex items-center justify-end">
        <img
          src={spendingPic}
          className=" md:w-[400px] w-[300px] md:h-[400px] h-[300px]"
          alt=""
        />
      </div>
    </div>
  );
}

export default Spending;
