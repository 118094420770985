import React from "react";
import Dashboard from "./Dashboard";
import SettingsHeader from "./components/settings/Header";
import Profile from "./components/settings/Profile";
import MobileHeader from "./components/MobileHeader";
import Header from "./components/budget/Header";
function Settings() {
  return (
    <Dashboard>
      <div className=" md:w-full w-screen   overflow-x-hidden overflow-y-scroll no-scrollbar ">
        <div className=" md:hidden block">
          <MobileHeader />
        </div>
        <div className=" hidden md:block">
          <Header />
        </div>
        <SettingsHeader />
      </div>
      <Profile />
    </Dashboard>
  );
}

export default Settings;
