import React from "react";
import phonePic from "../../src/assets/images/app.png";
import { GrPersonalComputer } from "react-icons/gr";
import { FaWallet } from "react-icons/fa6";
import { TbHandLoveYou } from "react-icons/tb";
import { Link } from "react-router-dom";

// bg-[#FDF1C6]
function Working() {
  return (
    <div className=" flex flex-wrap p-4 md:p-0 bg-gray-100">
      <div className=" md:pl-20 md:w-1/2 w-full">
        <div className=" text-4xl font-bold pt-20 pb-10">
          How Anthonomics Works
        </div>
        <div className=" flex flex-col space-y-6">
          <div className=" flex items-center space-x-3">
            <div className="">
              <GrPersonalComputer className=" text-5xl text-[#3B5EDA]" />
            </div>
            <div className="">
              <div className=" font-semibold text-xl text-gray-800">
                Register for Free
              </div>
              <div className="text-gray-800">
                Start your financial journey with our app at no cost. No hidden
                fees, no strings attached—just simple, effective tools to help
                you manage your money better.
              </div>
            </div>
          </div>
          <div className=" flex items-center space-x-3">
            <div className="">
              <FaWallet className=" text-5xl text-[#84DD3E]" />
            </div>
            <div className="">
              <div className=" font-semibold text-xl text-gray-800">
                Select a Budget That Works for You
              </div>
              <div className="text-gray-800">
                Choose from a variety of budgeting styles, like the 50/30/20
                rule. Tailor your budget to fit your unique financial situation
                and goals, ensuring that you stay on track every month.
              </div>
            </div>
          </div>
          <div className=" flex items-center space-x-3">
            <div className="">
              <TbHandLoveYou className=" text-5xl text-[#FC6D58]" />
            </div>
            <div className="">
              <div className=" font-semibold text-xl text-gray-800">
                Watch Your Expenses and Investments
              </div>
              <div className="text-gray-800">
                Easily track your spending, monitor your investments, and make
                informed decisions. Our app gives you real-time insights into
                your finances, helping you see where your money is going and how
                you can improve.
              </div>
            </div>
          </div>
          <div className="">
            <Link
              to="/register"
              className=" bg-[#84DD3E] px-4 py-2 rounded-md  "
            >
              Sign Up for Free
            </Link>
          </div>
        </div>
      </div>
      <div className=" md:w-1/2 w-full  flex items-center justify-center">
        <img
          src={phonePic}
          className=" md:w-[400px] w-[300px] md:h-[600px] h-[500px]"
          alt=""
        />
      </div>
    </div>
  );
}

export default Working;
