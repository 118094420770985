import { apiSlice } from "../../../features/api/apiSlice";

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpenses: builder.query({
      query: () => {
        return {
          url: "/expense",
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (responseData) => {
        return responseData.expenses;
      },
      providesTags: (result, error, arg) => {
        if (!result) {
          return []; // No tags to provide if there is no result
        }

        return [
          { type: "Expense", id: "LIST" },
          result.map(({ expenseId }) => {
            return { type: "Expense", expenseId };
          }),
        ];
      },
    }),
    addExpense: builder.mutation({
      query: (data) => ({
        url: `/expense/add`,
        method: "PUT",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, { id: expenseId }) => {
        console.log("id", expenseId);
        return [{ type: "Expense", expenseId }, { type: "Profile" }];
      },
    }),
    updateExpense: builder.mutation({
      query: (data) => ({
        url: `/expense/update`,
        method: "PUT",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        console.log("id", error);
        if (!arg) {
          return []; // No tags to provide if there is no result
        }
        const { expenseId } = arg;
        return [{ type: "Expense", expenseId }, { type: "Profile" }];
      },
    }),
    deleteExpense: builder.mutation({
      query: (data) => ({
        url: `/expense/delete`,
        method: "PUT",
        credentials: "include",
        body: data,
      }),
      invalidatesTags: (result, error, { id: expenseId }) => {
        console.log("id", expenseId);
        return [{ type: "Expense", expenseId }, { type: "Profile" }];
      },
    }),
    getExpensesByDateAndCategory: builder.query({
      query: (date) => {
        return {
          url: "/expense/date/category",
          method: "POST",
          body: date,
          credentials: "include",
        };
      },
      transformResponse: (responseData) => {
        return responseData;
      },
      providesTags: (result, error, arg) => {
        if (!result) {
          return []; // No tags to provide if there is no result
        }

        if (result) {
          return [
            { type: "Expense", id: "LIST" },
            result?.flatMap(({ expenses }) => {
              expenses?.map(({ expenseId }) => {
                console.log(expenseId);
                return { type: "Expense", expenseId };
              });
            }),
          ];
        }
      },
    }),
  }),
});

export const {
  useGetExpensesQuery,
  useGetExpensesByDateAndCategoryQuery,
  useAddExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
} = extendedApiSlice;
// returns the query result object
