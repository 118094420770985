import React from "react";
import Register from "./components/auth/Register";
function RegisterPage() {
  return (
    <div>
      <Register />
    </div>
  );
}

export default RegisterPage;
