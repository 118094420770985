import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { MdAccountBalance } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { CgProfile } from "react-icons/cg";

function BottomNav() {
  const location = useLocation();

  return (
    <div className=" w-full bg-gray-900 py-2 px-2 mt-4 text-white flex justify-between">
      <Link
        to="/dashboard"
        className={`${
          location.pathname == "/dashboard" && "bg-green-600 px-1 "
        } p-0.5 rounded-lg text-white cursor-pointer flex flex-col items-center   `}
      >
        <div className="">
          <FaMoneyCheckAlt className=" text-xs" />
        </div>
        <div className=" text-xs ">Budget</div>
      </Link>
      <Link
        to="/dashboard/reports"
        className={`${
          location.pathname == "/dashboard/reports" && "bg-green-600 px-1"
        }   p-0.5 rounded-lg text-white cursor-pointer flex flex-col items-center`}
      >
        <div className="">
          <GoGraph className=" text-xs" />
        </div>
        <div className=" text-xs ">Reports</div>
      </Link>
      <Link
        to="/dashboard/accounts"
        className={`${
          location.pathname == "/dashboard/accounts" && "bg-green-600 px-1"
        }   p-0.5 rounded-lg text-white cursor-pointer flex flex-col items-center`}
      >
        <div className="">
          <MdAccountBalance className=" text-xs" />
        </div>
        <div className=" text-xs ">Income</div>
      </Link>
      <Link
        to="/dashboard/settings"
        className={`${
          location.pathname == "/dashboard/settings" && "bg-green-600 px-1"
        }   p-0.5 rounded-lg text-white cursor-pointer flex flex-col items-center`}
      >
        <div className="">
          <CgProfile className=" text-xs" />
        </div>
        <div className=" text-xs ">Settings</div>
      </Link>
    </div>
  );
}

export default BottomNav;
