import React from "react";
import { IoArrowBack } from "react-icons/io5";
import computerSvg from "../../../assets/images/computer.svg";
import { useSelector, useDispatch } from "react-redux";
import { inDebted, notInDebt, goBack } from "../../onBoarding/onBoardingSlice";

function NotInDebtModal({ closeHandler }) {
  const inDebt = useSelector((state) => state.onBoarding.inDebt);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="fixed w-full inset-0 z-10 overflow-hidden flex justify-center items-end md:items-center animated fadeIn faster"
        style={{ background: "rgba(0,0,0,.9)" }}
      >
        <div className="shadow-xl modal-container bg-[#2c396af1] text-white w-full md:w-3/5 lg:w-3/5 xl:w-1/3 mx-auto rounded-t-lg md:rounded-lg z-100 overflow-y-auto max-h-full">
          <div className="modal-content text-left">
            <div className=" flex mt-2 ml-5">
              <div
                className="p-1 hover:bg-gray-100 hover:text-black rounded-full    cursor-pointer"
                onClick={() => dispatch(goBack())}
              >
                <IoArrowBack className=" text-xl" />
              </div>
            </div>
            <div className="px-6 pb-6">
              <div className=" flex justify-center items-center pt-4">
                <img src={computerSvg} className=" w-28 h-28" alt="" />
              </div>
              <div className="py-1 pt-10 text-center">
                <div className=" text-xl font-semibold">
                  Would you like us to suggest a budgeting plan for you?
                </div>
              </div>
              <div className=" flex justify-between space-x-10">
                <button
                  className=" bg-green-400 hover:bg-white hover:text-black mt-6 w-full flex justify-center items-center rounded-md px-4 py-1"
                  onClick={() => dispatch(inDebted())}
                >
                  Yes
                </button>
                <button
                  className=" bg-green-400 hover:bg-white hover:text-black mt-6 w-full flex justify-center items-center rounded-md px-4 py-1"
                  onClick={() => dispatch(notInDebt())}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotInDebtModal;
