import React from "react";

function Join() {
  return (
    <div className=" bg-[#19223C] text-white  md:pb-10 pb-4  md:pt-20 pt-10 md:px-14 px-7 ">
      <div className=" text-4xl font-bold py-5">
        Join a Community of Savvy Savers
      </div>
      <div className=" py-4 pb-12 font-semibold text-gray-200 md:w-3/4">
        We've dedicated the past one year to developing an app that would help
        countless individuals take control of their finances, and we're excited
        to offer our app to you for FREE. With our app, you can manage your
        money more effectively, saving more, spending less, and gaining a clear
        view of your financial life.
      </div>
      <div className="text-xs font-semibold  text-gray-300 md:w-3/4">
        While we believe we can help our users save and invest over $1 billion
        within the next 5years, we believe the real value lies in empowering
        each person to make the most of their money.It’s a testament to what’s
        possible when you have the right tools at your fingertips. Start your
        journey to financial freedom today and see how our app can help you
        reach your goals.
      </div>
    </div>
  );
}

export default Join;
