import React from "react";
import Navbar from "./Navbar";
import Banner from "./Banner";
import Join from "./Join";
import Features from "./Features";
import Working from "./Working";
import Footer from "./Footer";
import Spending from "./Spending";
import UserStory from "./UserStory";
function Index() {
  return (
    <div className="">
      <Navbar />
      <Banner />
      <Join />
      <Spending />
      <Features />
      <Working />
      <UserStory />
      <Footer />
    </div>
  );
}

export default Index;
