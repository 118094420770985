import { apiSlice } from "../../../features/api/apiSlice";
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => {
        return {
          url: "/register",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),

    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: "/login",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
    sendVerificationEmail: builder.mutation({
      query: () => {
        return {
          url: "/send-verification-email",
          method: "POST",
          credentials: "include"
        };
      },
    }),
    verifyEmail: builder.mutation({
      query: (token) => {
        return {
          url: `/verify-email/${token}`,
          method: "POST",
          credentials: "include",
        };
      },
    }),
    logoutUser: builder.mutation({
      query: () => {
        return {
          url: "/logout",
          method: "POST",
          credentials: "include",
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: "Expense" },
          { type: "Profile" },
          { type: "Income" },
          { type: "User" },
        ];
      },
    }),
    deleteUser: builder.mutation({
      query: () => {
        return {
          url: "/deleteUser",
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: "Expense" },
          { type: "Profile" },
          { type: "Income" },
          { type: "User" },
        ];
      },
    }),
    forgotPassword: builder.mutation({
      query: (data) => {
        return {
          url: "/forgot-password",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        return {
          url: "/reset-password",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useVerifyEmailMutation,
  useSendVerificationEmailMutation,
  useLogoutUserMutation,
  useDeleteUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = extendedApiSlice;
// returns the query result object
