import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import onBoardingReducer from "../dashboard/onBoarding/onBoardingSlice";
import authReducer from "../dashboard/components/auth/authSlice";
import reportReducer from "../dashboard/components/report/reportSlice";
import dateReducer from "../dashboard/components/buttons/dateSlice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    onBoarding: onBoardingReducer,
    auth: authReducer,
    report: reportReducer,
    date: dateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
