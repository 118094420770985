import React, { useState, useEffect } from "react";
import { Radio, Space } from "antd";
import { useGetProfileQuery, useUpdateProfileMutation } from "./profileSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currencies } from "currencies.json";
function Profile() {
  const [formError, setFormError] = useState();
  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const [updateProfile, { isLoading, error }] = useUpdateProfileMutation();

  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const [debt, setDebt] = useState(profileData?.debt ? profileData?.debt : 0);
  const [value, setValue] = useState(
    profileData?.budgetFormula == [7, 2, 1]
      ? 1
      : profileData?.budgetFormula == [6, 3, 1]
      ? 2
      : profileData?.budgetFormula == [5, 3, 2]
      ? 3
      : profileData?.budgetFormula == [5, 2, 3]
      ? 4
      : undefined
  );

  const [currency, setCurrency] = useState(profileData?.currency);
  const areArraysEqual = (arr1, arr2) =>
    arr1?.length === arr2?.length &&
    arr1?.every((value, index) => value === arr2[index]);
  useEffect(() => {
    setValue(
      areArraysEqual(profileData?.budgetFormula, [7, 2, 1])
        ? 1
        : areArraysEqual(profileData?.budgetFormula, [6, 3, 1])
        ? 2
        : areArraysEqual(profileData?.budgetFormula, [5, 3, 2])
        ? 3
        : areArraysEqual(profileData?.budgetFormula, [5, 2, 3])
        ? 4
        : undefined
    );
    setDebt(profileData?.debt ? profileData?.debt : 0);
    setCurrency(profileData?.currency);
  }, [loadingProfile]);
  const onChange = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };
  const onSubmit = async () => {
    if (value && currency && currency !== "") {
      setFormError("");
      try {
        const res = await updateProfile({
          budgetFormula:
            value == 1
              ? [7, 2, 1]
              : value == 2
              ? [6, 3, 1]
              : value == 3
              ? [5, 3, 2]
              : [5, 2, 3],
          currency: currency,
          debt: debt,
        }).unwrap();

        console.log(res);
        if (res?.userId) {
          toast.success("Profile Updated!");
          await delay(2000);
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("Something went wrong.");
        console.log(error);
      }
    } else {
      setFormError("Please fill in the data below!");
    }
  };
  return (
    <div className=" w-full border-t  border-gray-800 py-4">
      <div className=" px-4 flex flex-col space-y-4 md:w-3/4 w-full">
        <div className=" text-center text-red-500">{formError}</div>
        <div className="">
          <div className="block text-sm font-medium  text-gray-800">
            Select Currency
          </div>
          <select
            id="currency"
            name="currency"
            onChange={(e) => setCurrency(e.target.value)}
            autoComplete="currency"
            value={currency}
            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Select currency</option>
            {currencies.map((curr) => (
              <option value={curr?.code}>{curr?.name}</option>
            ))}
          </select>
        </div>
        <div className="">
          <div className="block text-sm font-medium  text-gray-800">
            Debt Amount
          </div>
          <input
            type="number"
            id="debt"
            name="debt"
            onChange={(e) => setDebt(e.target.value)}
            value={debt}
            autoComplete="debt"
            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
          <div className=" text-sm text-gray-500">
            50 % of your savings will go to debt payment
          </div>
        </div>
        <div className="  text-gray-800 mt-2">
          <div className=" text-lg">Choose a budgeting formula</div>
          <div className=" flex items-center space-x-4 text-xs tex">
            <div className="">
              <div className="">
                <span className="  text-gray-800">Needs:</span>
                _%
              </div>
            </div>
            <div className="">
              <div className="">
                <span className=" text-gray-800">Wants:</span>
                _%
              </div>
            </div>
            <div className="">
              <div className="">
                <span className=" text-gray-800">Investments and Savings:</span>
                _%
              </div>
            </div>
          </div>
          <div className=" pt-4  text-gray-800">
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio value={1} className=" text-gray-800">
                  <div className=" flex items-center space-x-4 ">
                    <div className="">
                      <div className="text-yellow-400">
                        <span className="  text-gray-800">Needs:</span> 70%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-blue-400">
                        <span className=" text-gray-800">Wants:</span> 20%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-green-400">
                        <span className=" text-gray-800">
                          Investments and Savings:
                        </span>
                        10%
                      </div>
                    </div>
                  </div>
                </Radio>
                <Radio value={2} className=" text-gray-800 py-2">
                  <div className=" flex items-center space-x-4 ">
                    <div className="">
                      <div className="text-yellow-400">
                        <span className="  text-gray-800">Needs:</span> 60%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-blue-400">
                        <span className=" text-gray-800">Wants:</span> 30%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-green-400">
                        <span className=" text-gray-800">
                          Investments and Savings:
                        </span>
                        10%
                      </div>
                    </div>
                  </div>
                </Radio>
                <Radio value={3} className=" text-gray-800 py-2">
                  <div className=" flex items-center space-x-4 ">
                    <div className="">
                      <div className="text-yellow-400">
                        <span className="  text-gray-800">Needs:</span> 50%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-blue-400">
                        <span className=" text-gray-800">Wants:</span> 30%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-green-400">
                        <span className=" text-gray-800">
                          Investments and Savings:
                        </span>
                        20%
                      </div>
                    </div>
                  </div>
                </Radio>
                <Radio value={4} className=" text-gray-800 py-2">
                  <div className=" flex items-center space-x-4 ">
                    <div className="">
                      <div className="text-yellow-400">
                        <span className="  text-gray-800">Needs:</span> 50%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-blue-400">
                        <span className=" text-gray-800">Wants:</span> 20%
                      </div>
                    </div>
                    <div className="">
                      <div className="text-green-400">
                        <span className=" text-gray-800">
                          Investments and Savings:
                        </span>
                        30%
                      </div>
                    </div>
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div className=" flex flex-row-reverse mx-4 ">
          <div
            className="bg-[#5C85E7]
           mt-4 text-white cursor-pointer flex items-center space-x-2 px-6 py-1 rounded-md"
            onClick={onSubmit}
          >
            {isLoading ? "Loading" : "Save"}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Profile;
