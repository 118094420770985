function Info({ title, info }) {
  return (
    <div className="  md:px-28 px-4 py-2">
      <div className="text-lg font-bold text-center">{title}</div>
      <div className="">{info}</div>
    </div>
  );
}

export default Info;
