import React from "react";
import logo from "../assets/images/logo.png";
import { useGetProfileQuery } from "../dashboard/components/settings/profileSlice";
import { Link } from "react-router-dom";
function Navbar() {
  const {
    data: profileData,
    isLoading: loadingProfile,
    error,
  } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  return (
    <div className=" flex justify-between items-center bg-white dark:bg-white   shadow-lg text-[#19223C] md:py-2 py-2 md:px-8 px-2 ">
      <div className=" bg-white dark:bg-white rounded-md">
        <img
          src={logo}
          alt=""
          className="md:w-50 md:h-20 w-60 h-16   rounded-lg"
        />
      </div>

      {!profileData && (
        <div className="  md:flex hidden  items-center md:text-lg md:ml-6 space-x-2 ">
          <Link
            to="/login"
            className=" px-4 py-1 border border-[#19223C] rounded-md "
          >
            Login
          </Link>
          <Link to="register" className=" px-4 py-1 bg-[#A2CC40] rounded-md">
            Get Started
          </Link>
        </div>
      )}
      {!profileData && (
        <div className="  flex md:hidden  items-center md:text-lg md:ml-6 space-x-2 ">
          <Link to="/register" className=" px-4 py-1 bg-[#A2CC40] rounded-md">
            Get Started
          </Link>
        </div>
      )}
      {profileData && (
        <div className="  flex   items-center md:text-lg md:ml-6 space-x-2 ">
          <Link to="/dashboard" className=" px-4 py-1 bg-[#A2CC40] rounded-md">
            Dashboard
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
