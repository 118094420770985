import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Index from "./landingPage/index";
import Privacy from "./privacy/Index";
import Budget from "./dashboard/Budget";
import Report from "./dashboard/Report";
import Accounts from "./dashboard/Accounts";
import LoginPage from "./dashboard/LoginPage";
import RegisterPage from "./dashboard/RegisterPage";
import VerifyEmailPage from "./dashboard/VerifyEmailPage";
import ForgotPasswordPage from "./dashboard/ForgotPasswordPage";
import ResetPasswordPage from "./dashboard/ResetPasswordPage";
import VerifyLinkPage from "./dashboard/VerifyLinkPage";
import Survey from "./dashboard/Survey";
import Settings from "./dashboard/Settings";
import { setUser } from "./dashboard/components/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
function App() {
  // const authUser = userStore((state) => state.user);
  // const storeUser = userStore((state) => state.setUser);
  const authUser = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const auth = getAuth();
  const [tokenUser, setTokenUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem("user", user.accessToken);
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        console.log(user);
        const id = user.uid;
        setTokenUser(user);
        dispatch(
          setUser({
            authenticated: true,
            id: id,
            email: user.email,
          })
        );
      } else {
        // storeUser({});
        // localStorage.removeItem("user");
      }
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const loaderElement = document.querySelector(".loader-container");
    if (loaderElement) {
      loaderElement.remove();
    }
    if (tokenUser) {
      refreshToken(); // Force refresh the token
    }
  }, []);
  const refreshToken = async () => {
    try {
      const token = await tokenUser.getIdToken(true); // Force refresh the token
      localStorage.setItem("user", token);
      const headers = { Authorization: `Bearer ${token}` };
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" ">
      <Router>
        <Routes>
          <Route exact path="/dashboard/reports" element={<Report />} />
          <Route exact path="/dashboard/settings" element={<Settings />} />
          <Route exact path="/dashboard/accounts" element={<Accounts />} />
          <Route exact path="/dashboard" element={<Budget />} />
          <Route exact path="/" element={<Index />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/verify-email" element={<VerifyEmailPage />} />
          <Route
            exact
            path="/verifyEmail/:token"
            element={<VerifyLinkPage />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPasswordPage />}
          />
          <Route
            exact
            path="/resetpassword/:resetLink"
            element={<ResetPasswordPage />}
          />
          <Route exact path="/survey" element={<Survey />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
