import React, { useState, useEffect } from "react";
import savingsSvg from "../../../assets/images/savings.svg";
import { useGetProfileQuery } from "../settings/profileSlice";
import { useGetIncomesByDateQuery } from "./incomeSlice";
import { useSelector } from "react-redux";
function Balance() {
  const [incTotal, setIncTotal] = useState();
  const { data: profData } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const currentDate = useSelector((state) => state.date);

  const { data, isLoading, isSuccess, isFetching, refetch, isError, error } =
    useGetIncomesByDateQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });
  useEffect(() => {
    const incomesTotal = data
      ? data.reduce((sum, income) => sum + parseInt(income.amount), 0)
      : 0;
    setIncTotal(incomesTotal);
  }, [data]);

  return (
    <div className=" flex w-full overflow-x-hidden flex-wrap md:gap-x-4 gap-x-2 gap-y-2  items-center px-4 py-2 text-white">
      <div className=" flex flex-col space-y-2 bg-[#FFE2E6] w-[150px]     rounded-lg    p-2   ">
        <div className="">
          <img src={savingsSvg} className=" w-6 h-6" alt="" />
        </div>
        <div className="  text-gray-900">
          <div className=" text-sm ">Total Income</div>

          {!isLoading && (
            <div className=" text-sm">
              {profData?.currency} {incTotal?.toFixed(2)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Balance;
