import React from "react";
import { FaBrain } from "react-icons/fa";
import { CiMoneyBill } from "react-icons/ci";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsGraphUpArrow } from "react-icons/bs";

function Features() {
  return (
    <div className=" md:px-20 md:py-20 p-4 flex flex-col items-center ">
      <div className=" text-center text-5xl text-gray-800 font-bold">
        Explore Anthonomics Features
      </div>
      <div className=" grid md:grid-cols-2 grid-cols-1 md:gap-x-20   gap-y-4  md:pt-10 pt-5">
        <div className=" flex flex-col space-y-1 items-center justify-center md:w-[350px] w-full">
          <FaBrain className=" text-6xl mb-8 text-[#A2CC40]" />
          <div className=" text-xl font-bold text-gray-800 ">
            Create a Budget That Works for You
          </div>
          <div className=" text-gray-500">
            Take control of your finances with a budget tailored to your
            lifestyle. Whether you prefer the 50/30/20 rule or a custom plan
            that suits your unique needs, our app helps you create and stick to
            a budget that works for you. Easily allocate your income towards
            essentials, savings, and discretionary spending, ensuring you stay
            on track and reach your financial goals.
          </div>
        </div>
        <div className=" flex flex-col space-y-1 items-center justify-center md:w-[350px] w-full">
          <CiMoneyBill className=" text-6xl mb-8 text-[#A2CC40]" />
          <div className=" text-xl font-bold text-gray-800 ">
            Change Your Budget Style at Will
          </div>
          <div className=" text-gray-500">
            Flexibility is key when it comes to budgeting. Life is dynamic, and
            your budgeting style should be too. Our app allows you to switch
            between different budgeting methods with ease. Whether you're
            adjusting to a new financial situation or simply want to experiment
            with a different approach, you can adapt your budget at any time to
            fit your current needs and goals.
          </div>
        </div>
        <div className=" flex flex-col space-y-1 items-center justify-center md:w-[350px] w-full">
          <GiTakeMyMoney className=" text-6xl mb-8 text-[#A2CC40]" />
          <div className=" text-xl font-bold text-gray-800 ">
            Manage Your Needs, Wants, and Savings Each Month
          </div>
          <div className=" text-gray-500">
            Achieve a balanced financial life by effectively managing your
            needs, wants, and savings. Our app helps you categorize your
            expenses, ensuring that your essentials are covered while still
            allowing room for enjoyment and future savings. By keeping your
            spending in check and aligning it with your priorities, you can
            build a healthy financial routine that supports both your present
            and future self.
          </div>
        </div>
        <div className=" flex flex-col space-y-1 items-center justify-center md:w-[350px] w-full">
          <BsGraphUpArrow className=" text-6xl mb-8 text-[#A2CC40]" />
          <div className=" text-xl font-bold text-gray-800 ">
            Watch and Grow Your Savings and Investments
          </div>
          <div className=" text-gray-500">
            Monitor your savings and investments in real-time and see your
            wealth grow. With our intuitive tools, you can track your savings
            progress. Set goals and watch as your financial future becomes
            brighter. Our app provides insights and analytics to help you make
            informed decisions and optimize your growth.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
