import React from "react";
import PieChart from "../charts/PieChart";
import BarChart from "../charts/BarChart";
import IncomeChart from "../charts/IncomeChart";
import { useSelector } from "react-redux";

function Charts() {
  const category = useSelector((state) => state.report.category);
  const navigation = useSelector((state) => state.report.navigation);

  return (
    <div className=" px-4  text-gray-900">
      <div className=" md:text-2xl text-lg font-bold py-2">
        {(navigation == "Expenditure" && "Spending") || "Income"} Totals
      </div>
      <div className=" ">{category}</div>
      <div className=" flex justify-center items-center">
        {(navigation == "Expenditure" &&
          ((category == "All Categories" && <BarChart />) || (
            <div className="h-5/6">
              <PieChart />
            </div>
          ))) || (
          <div className="h-5/6">
            {" "}
            <IncomeChart />
          </div>
        )}
      </div>
    </div>
  );
}

export default Charts;
