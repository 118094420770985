import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import BottomNav from "./components/BottomNav";
import DebtModal from "./components/modals/DebtModal";
import InDebtedModal from "./components/modals/InDebtedModal";
import NotInDebtModal from "./components/modals/NotInDebtModal";
import { useSelector, useDispatch } from "react-redux";
import { useGetProfileQuery } from "./components/settings/profileSlice";
import { useNavigate } from "react-router-dom";
import { setUser } from "./components/auth/authSlice";
function Dashboard({ children }) {
  const [showDebtModal, setShowDebtMoal] = useState(true);
  const dispatch = useDispatch();
  const inDebt = useSelector((state) => state.onBoarding.inDebt);
  const step = useSelector((state) => state.onBoarding.step);
  const {
    data: profileData,
    isLoading: loadingProfile,
    error,
  } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (profileData) {
      dispatch(
        setUser({
          authenticated: true,
          id: profileData.userId,
          email: profileData.email,
        })
      );
    }
    console.log(profileData);
    if (!loadingProfile && !profileData) {
      return (window.location.href = "/login");
    }
    if (
      (profileData?.emailVerified == false || !profileData?.emailVerified) &&
      !loadingProfile
    ) {
      console.log(profileData);
      return (window.location.href = "/verify-email");
    }
    if (profileData?.budgetFormula?.length < 1 && !loadingProfile) {
      return navigate("/dashboard/settings");
    }
  }, [profileData, loadingProfile]);
  return (
    <div className=" h-screen  bg-gray-100">
      <div className="md:flex h-full">
        <div className="md:block hidden">
          <Sidebar />
        </div>

        <div className=" md:flex-grow h-full overflow-y-scroll pb-40">
          {children}
        </div>
      </div>
      <div className=" md:hidden block fixed bottom-0 w-full ">
        <BottomNav />
      </div>
      {/* {step == 0 && <DebtModal closeHandler={() => setShowDebtMoal(false)} />}
      {step == 1 && inDebt && (
        <InDebtedModal closeHandler={() => setShowDebtMoal(false)} />
      )}
      {step == 1 && !inDebt && (
        <NotInDebtModal closeHandler={() => setShowDebtMoal(false)} />
      )} */}
    </div>
  );
}

export default Dashboard;
