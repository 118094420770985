import Navbar from "../landingPage/Navbar";
import Footer from "../landingPage/Footer";
import Info from "./Info";
function Index() {
  return (
    <div className="">
      <Navbar />
      <div className=" text-xl font-semibold text-center">Privacy Policy</div>

      <Info
        title={"Introduction"}
        info={` At Anthonomics, we value your privacy and are committed to protecting
          your personal information. This Privacy Policy outlines how we
          collect, use, and protect your data when you use our app and services.
          By using Anthonomics, you agree to the terms of this policy.`}
      />

      <div className="  md:px-28 px-4 py-2">
        <div className="text-lg font-bold text-center">
          Information We Collect{" "}
        </div>
        <div className="">
          <ul className=" list-disc">
            <li>
              Personal Information: When you register for Anthonomics, we may
              collect personal information such as your name, email address, and
              other contact details.
            </li>
            <li>
              Financial Information: We may collect data related to your
              financial accounts, expenses, and budgeting preferences to provide
              personalized financial services.
            </li>
            <li>
              Usage Data: We collect information about how you interact with our
              app, including your IP address, device information, and browsing
              history.
            </li>
          </ul>
        </div>
      </div>
      <div className="  md:px-28 px-4 py-2">
        <div className="text-lg font-bold text-center">
          How We Use Your Information
        </div>
        <div className="">
          <div className="">We use your information to:</div>
          <ul className=" list-disc">
            <li>
              Provide and improve our services and provide added benefits to
              you.
            </li>
            <li>
              Personalize your experience with customized financial insights
            </li>
            <li>
              Communicate with you about updates, new features, and promotions
            </li>
            <li>Analyze usage trends to enhance our app’s functionality</li>
            <li>Comply with legal obligations</li>
          </ul>
        </div>
      </div>
      <div className="  md:px-28 px-4 py-2">
        <div className="text-lg font-bold text-center">
          Sharing Your Information
        </div>
        <div className="">
          <div className="">
            We do not sell your personal information. We may share your
            information with:
          </div>
          <ul className=" list-disc">
            <li>
              Service Providers: Third-party service providers who assist us in
              operating our app and delivering services, under strict
              confidentiality agreements.
            </li>
            <li>
              Legal Compliance: When required by law, we may disclose your
              information to authorities or regulatory bodies.
            </li>
          </ul>
        </div>
      </div>

      <Info
        title={"Data Security"}
        info={` We implement robust security measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no security system is impenetrable, and we cannot guarantee the complete security of your information.
`}
      />
      <div className="  md:px-28 px-4 py-2">
        <div className="text-lg font-bold text-center">Your Rights</div>
        <div className="">
          <div className="">
            Depending on your location, you may have the following rights
            regarding your personal data:
          </div>
          <ul className=" list-disc">
            <li>Access, correct, or delete your personal information</li>
            <li>Withdraw consent for data processing</li>
            <li>Object to or restrict certain types of data processing</li>
            <li>Request a copy of your personal data in a portable format</li>
          </ul>
          <div className="">
            To exercise these rights, please contact us at
            anthonomics@gmail.com.
          </div>
        </div>
      </div>
      <Info
        title={"Cookies and Tracking Technologies"}
        info={`Anthonomics may use cookies and similar tracking technologies to enhance your user experience. You can manage your cookie preferences through your browser settings.
`}
      />
      <Info
        title={"Cross-Border Data Transfers"}
        info={`If you are accessing Anthonomics from outside North America, please be aware that your information may be transferred to, stored, and processed in North America or other countries where our servers are located. We comply with applicable data protection laws, including GDPR for users in the EU, ensuring that your information is adequately protected.
`}
      />
      <Info
        title={"Local Legal Compliance"}
        info={`Anthonomics is committed to complying with the local data protection laws of the jurisdictions in which it operates, including but not limited to GDPR, CCPA, and PIPEDA.
`}
      />
      <Info
        title={"Children’s Privacy"}
        info={`Our app is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have collected information from a child, we will take steps to delete it.
`}
      />
      <Info
        title={"Changes to This Privacy Policy"}
        info={`We may update this Privacy Policy from time to time. When we make changes, we will notify you by updating the effective date and providing notice within the app.
`}
      />
      <Info
        title={"Contact Us"}
        info={`If you have any questions or concerns about this Privacy Policy or your data, please contact us at:
anthonomics@gmail.com
au.chukwuma@gmail.com `}
      />
      <Footer />
    </div>
  );
}

export default Index;
