import React from "react";
import userPic from "../../../assets/images/user.png";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useGetProfileQuery } from "../settings/profileSlice";
import {
  useLogoutUserMutation,
  useDeleteUserMutation,
} from "../auth/userSlice";
import { CgWindows } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
function Header() {
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();
  const [logoutUser, { isLoading: loading, error: errorMsg }] =
    useLogoutUserMutation();
  const [deleteUser, { isLoading: delLoading, error: errorDelMsg }] =
    useDeleteUserMutation();
  const logout = async () => {
    try {
      const res = await logoutUser().unwrap();
      window.location.href = "/login";

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAccount = async () => {
    try {
      const res = await deleteUser().unwrap();
      window.location.href = "/login";

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" flex justify-between w-full px-5 items-center pt-4">
      <div className=" text-lg  text-gray-800 font-bold">Dashboard</div>

      <div className=" flex items-center space-x-4 ">
        <div className=" text-green-500">
          <MdOutlineNotificationsActive className="" />
        </div>
        <div className="">
          <img src={userPic} alt="" className=" h-6 w-6 " />
        </div>
        <div className="">
          <div className=" text-lg  text-gray-900 ">
            {profileData?.email?.split("@")[0]}
          </div>
          <div className=" text-xs  text-gray-600">User</div>
        </div>
        <div className=" p-1">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton
                className={
                  "inline-flex w-full justify-center items-center gap-x-0.5 rounded-md  py-2   text-gray-100 "
                }
              >
                <ChevronDownIcon
                  aria-hidden="true"
                  className={"-mr-1 h-5 w-5  text-gray-600"}
                />
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem onClick={logout}>
                  <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer">
                    Logout
                  </div>
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem onClick={deleteAccount}>
                  <div className="block px-4 py-2 text-sm text-red-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 cursor-pointer">
                    {!delLoading ? "Delete Account" : "Deleting"}
                  </div>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default Header;
