import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoOptionsOutline } from "react-icons/io5";
import Table from "./Table";

function IncomeTable() {
  return (
    <div className=" py-4 w-full   text-gray-900   px-2">
      <div className=" flex flex-wrap justify-between px-4 pb-2 w-5/6">
        <div className=" text-lg font-semibold">Transactions</div>
        <div className="">
          <input
            className=" border border-gray-500 rounded-lg"
            type="text"
            placeholder=" Search Transactions"
          />
        </div>
      </div>
      <div className=" w-full ">
        <Table />
      </div>
    </div>
  );
}

export default IncomeTable;
