import React from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
function ForgotPasswordPage() {
  return (
    <div>
      <ForgotPassword />
    </div>
  );
}

export default ForgotPasswordPage;
