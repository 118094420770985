import React, { useState } from "react";
import { GiCash } from "react-icons/gi";
import { FaLock } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import FundModal from "../modals/FundModal";
import { useAddIncomeMutation } from "./incomeSlice";
function Header() {
  const [showFundModal, setShowFundModal] = useState(false);
  const [income, setIncome] = useState();
  const [amount, setAmount] = useState();
  const [category, setCategory] = useState();

  const [addIncome, { isLoading: loading, error: errorMsg }] =
    useAddIncomeMutation();
  const addOneIncome = async () => {
    try {
      const res = await addIncome({
        income: income,
        amount: amount,
        category: "investments",
      }).unwrap();
      console.log(res);
      setShowFundModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" py-6 px-4 w-full overflow-x-hidden flex flex-wrap gap-y-2 justify-between items-center  text-gray-900">
      <div className="">
        <div className=" md:text-2xl pb-1 uppercase font-bold  text-gray-900">
          My Account
        </div>
        <div className=" flex items-center space-x-4">
          <div className=" flex items-center space-x-1">
            <div className="">
              <GiCash className=" text-xs" />
            </div>
            <div className=" text-xs">Cash</div>
          </div>
          <div className=" flex items-center space-x-1">
            <div className="">
              <FaLock className=" text-xs" />
            </div>
            <div className=" text-xs">Reconciled</div>
          </div>
        </div>
      </div>
      <div className=" flex flex-wrap gap-x-2">
        <button
          className=" flex items-center bg-green-600 px-3 space-x-1  py-1 rounded-xl text-white "
          onClick={() => setShowFundModal(true)}
        >
          <div className=" text-sm ">Fund</div>
          <IoIosAddCircle className="" />
        </button>
        <button className=" flex items-center  px-3 space-x-1  py-1 rounded-xl bg-red-500 text-white ">
          <div className=" text-sm ">Delete </div>
          <MdDelete className="" />
        </button>
      </div>
      {showFundModal && (
        <FundModal
          closeHandler={() => setShowFundModal(false)}
          addOneIncome={addOneIncome}
          setIncome={setIncome}
          setAmount={setAmount}
          setCategory={setCategory}
          income={income}
          amount={amount}
          category={category}
          loading={loading}
        />
      )}
    </div>
  );
}

export default Header;
