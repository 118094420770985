import React from "react";

import bgImage from "../../assets/images/mula3.png";

function SideBanner() {
  return (
    <div className=" w-full  relative  pt-16  bg-gradient-to-r from-white to-white  h-full ">
      <img src={bgImage} className=" h-full w-full  object-cover" alt="" />
    </div>
  );
}

export default SideBanner;
