import React from "react";
import VerifyEmail from "./components/auth/VerifyEmail";
function VerifyEmailPage() {
  return (
    <div>
      <VerifyEmail />
    </div>
  );
}

export default VerifyEmailPage;
