import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api", // optional
  baseQuery: fetchBaseQuery({
    baseUrl: "https://prod.anthonomics.com",
    // prepareHeaders: (headers, { getState }) => {
    //   const token = localStorage.getItem("user");

    //   if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    //   }

    //   return headers;
    // },
  }),

  tagTypes: ["Expense", "Income", "Profile", "User"],
  endpoints: (builder) => ({}),
});
