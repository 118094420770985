import React from "react";
import MobileHeader from "./components/MobileHeader";
import FundingCategory from "./components/budget/FundingCategory";
import Category from "./components/budget/Category";
import BudgetCategory from "./components/budget/MobileCategory";
import Details from "./components/budget/Details";
import Dashboard from "./Dashboard";
import TopExpenses from "./components/report/TopExpenses";

// import Header from "./components/Header";
import Header from "./components/budget/Header";
function Budget() {
  return (
    <Dashboard>
      <div className=" w-full">
        <div className=" md:hidden block">
          <MobileHeader />
        </div>
        <div className=" hidden md:block">
          <Header />
        </div>
        <div className="">
          <FundingCategory />
        </div>
        <div className=" border-t mt-4 border-gray-400 grid grid-cols-3">
          <div className=" md:col-span-2 col-span-3">
            <div className=" md:block hidden">
              <Category />
            </div>
            <div className="block md:hidden">
              <BudgetCategory />
            </div>
          </div>
          <div className=" md:col-span-1 col-span-3   bg-gray-200  h-full  p-4">
            <Details />
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default Budget;
