import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
import moneyBagSvg from "../../../assets/images/money.svg";
import { FcGoogle } from "react-icons/fc";
import { signInWithGoogle, signIn } from "../../../utils/config";
import SideBanner from "../SideBanner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../utils/Spinner";
import { useForgotPasswordMutation } from "./userSlice";
import logo from "../../../assets/images/logo.png";
import darkLogo from "../../../assets/images/darkLogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgotPassword, { isLoading, error: errorMsg }] =
    useForgotPasswordMutation();

  const sendLinkFunction = async () => {
    if (email && email?.length > 1) {
      try {
        setError("");
        setSuccess("");
        setLoading(true);
        const res = await forgotPassword({
          email,
        }).unwrap();
        console.log(res);
        if (res?.message) {
          setSuccess("Reset Link sent.Please check your email");
          toast.success("Reset Link sent.Please check your email");
          setEmail("");
        }
        setLoading(false);
        if (res.error) {
          setError("Something went wrong");
          toast.error("Something went wrong");
        }
      } catch (error) {
        setError(error?.data?.error);
        toast.error(error?.data?.error);

        console.log(error);
        setLoading(false);
      }
    }
  };
  return (
    <div className=" flex  h-screen ">
      <div className=" md:w-1/2 w-full bg-white h-full overflow-y-scroll flex justify-center p-10">
        <div className=" md:w-3/4 w-full ">
          <div className=" flex justify-center items-center ">
            <img src={logo} className="w-60 h-16 rounded-lg" alt="" />
          </div>

          <div className=" text-sm text-gray-500 pb-4 text-center">
            Your Budgeting Companion
          </div>
          <div className=" text-sm text-gray-500 pt-4 text-center">
            Please enter your email
          </div>
          {/* {error?.length > 1 && (
            <div className="text-sm text-red-500 pt-4 text-center capitalize">
              {error}
            </div>
          )}
          {success?.length > 1 && (
            <div className="text-sm text-green-500 pt-4 text-center capitalize">
              {success}
            </div>
          )} */}
          <div className=" pt-12">
            <div className="text-gray-500 text-sm">Email</div>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder=" Enter Email"
              className=" border border-gray-800    rounded-lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className=" flex justify-center items-center pt-4 w-full">
            <button
              className=" bg-[#13143E] text-white w-full py-1 rounded-full"
              onClick={sendLinkFunction}
            >
              {loading ? <Spinner /> : " Submit"}
            </button>
          </div>
          <div className=" flex  justify-center items-center pt-4 w-full">
            <div
              className="   text-[#20203C] w-full  flex items-center cursor-pointer py-1 rounded-md  justify-center"
              onClick={() => {}}
            >
              <Link to="/login" className=" flex items-center  space-x-2">
                <div className=" text-sm">Back to Login</div>
              </Link>
            </div>
          </div>
          <div className="pt-8 flex items-center justify-center">
            <div className=" text-sm text-gray-500">
              Anthonomics 2024 | All rights reserved
            </div>
          </div>
        </div>
      </div>
      <div className=" md:w-1/2 md:block hidden  h-full overflow-y-scroll">
        <SideBanner />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
