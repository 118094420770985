import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdElectricBolt } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import PieChart from "../charts/PieChart";

function Details() {
  return (
    <div className="">
      {/* <div className=" bg-[#C1EE9F] flex items-center md:gep-x-20 gap-x-4 justify-between px-3 py-1 rounded-lg">
        <div className="">
          <div className=" font-bold ">KSh20,900.00</div>
          <div className=" text-xs">Ready to Assign</div>
        </div>
        <div className="">
          <button className=" bg-[#346011] rounded-md text-white  px-2 py-1 flex items-center gap-x-4">
            <div className="">Auto-Assign</div>
          </button>
        </div>
      </div> */}
      <div className=" mt-4  rounded-lg ">
        <div className="  text-gray-900 pb-1 text-lg text-center ">
          Utilities Distribution
        </div>
        <PieChart />
      </div>
    </div>
  );
}

export default Details;
