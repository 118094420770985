import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
import moneyBagSvg from "../../../assets/images/money.svg";
import { FcGoogle } from "react-icons/fc";
import { signInWithGoogle, signIn } from "../../../utils/config";
import SideBanner from "../SideBanner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../utils/Spinner";
import { useLoginUserMutation } from "./userSlice";
import logo from "../../../assets/images/logo.png";
import darkLogo from "../../../assets/images/darkLogo.png";

function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginUser, { isLoading: loadingLogin, error: loginErrorMsg }] =
    useLoginUserMutation();
  const login = async () => {
    setLoading(true);
    try {
      const res = await signInWithGoogle();
      console.log(res);

      //   navigate("/dashboard");
    } catch (error) {}
  };
  // useEffect(() => {
  //   if (user.email) {
  //     setLoading(false);
  //     navigate("/dashboard");
  //   }
  // }, [user]);
  const loginUserFunction = async () => {
    try {
      setError("");

      setLoading(true);
      const res = await loginUser({
        email,
        password,
      }).unwrap();
      // const res = await signIn({
      //   email,
      //   password,
      // });
      console.log(res);
      if (res?.session?.user?.email) {
        navigate("/dashboard");
      }
      setLoading(false);
      if (res.error) {
        setError(res.error);
      }
    } catch (error) {
      setError(error?.data?.message);
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className=" flex  h-screen ">
      <div className=" md:w-1/2 md:block hidden  h-full overflow-y-scroll">
        <SideBanner />
      </div>
      <div className=" md:w-1/2 w-full bg-white  h-full overflow-y-scroll  px-4 md:py-10 py-8">
        <div className=" grid md:grid-cols-2 grid-cols-1   ">
          <div className="flex items-center md:justify-normal  justify-center ">
            <img src={logo} className="w-60 h-16 rounded-lg" alt="" />
          </div>
          <div className=" md:flex items-center justify-end space-x-4  hidden ">
            <div className=" text-sm">Are you a new user?</div>
            <Link
              to="/register"
              className=" text-sm shadow-lg text-[#A2CC40] font-semibold rounded-full border px-4 py-2 border-gray-200 "
            >
              Enroll Now
            </Link>
          </div>
        </div>
        <div className="  w-full md:px-10 px-2 pt-6 ">
          <div className=" text-center text-4xl font-bold pt-4">
            Welcome Back
          </div>
          <div className=" text-sm text-gray-500  text-center">
            Please Login to your account
          </div>
          {error?.length > 1 && (
            <div className="text-sm text-red-500 pt-4 text-center capitalize">
              {error}
            </div>
          )}
          <div className=" pt-12">
            <div className="text-gray-500 text-sm">Email</div>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder=" Enter Email"
              className=" border border-gray-800    rounded-lg"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className=" pt-4">
            <div className="text-gray-500 text-sm">Password</div>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder=" Enter Password"
              type={showPassword ? "text" : "password"}
              className="  border border-gray-800  rounded-lg "
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              addonAfter={
                <EyeOutlined
                  className="site-form-item-icon cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                />
              }
            />
          </div>
          <div className=" pt-4  flex justify-between items-center">
            <div className=" flex items-center space-x-2">
              <input
                type="checkbox"
                id="remember"
                className="  cursor-pointer "
                name="remember"
                value="remember"
              />
              <div className=" text-gray-500 text-sm">Remember me</div>
            </div>
            <Link
              to={"/register"}
              className=" text-sm text-gray-500 cursor-pointer"
            >
              Create Account
            </Link>
          </div>
          <div className=" flex justify-center items-center pt-4 w-full">
            <button
              className=" bg-[#13143E]  text-white w-full py-1 rounded-full"
              onClick={loginUserFunction}
            >
              {loading ? <Spinner /> : " Login"}
            </button>
          </div>
          <div className=" flex  justify-center items-center pt-4 w-full">
            <div
              className="   text-[#20203C] w-full  flex items-center cursor-pointer py-1 rounded-md  justify-center"
              onClick={() => {}}
            >
              <Link
                to="/forgot-password"
                className=" flex items-center  space-x-2"
              >
                <div className=" text-sm">Forgot Password?</div>
              </Link>
            </div>
          </div>
          <div className="pt-8 flex items-center justify-center">
            <div className=" text-sm text-gray-500">
              Anthonomics 2024 | All rights reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
