import React from "react";
import { MdFileCopy } from "react-icons/md";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "../buttons/DatePicker";
import { setCategory } from "./reportSlice";

import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  info: {
    flexDirection: "row",
    paddingTop: 10,
    marginRight: 5,
  },
  details: {
    paddingTop: 20,
    marginRight: 5,
  },
  title: {
    textAlign: "center",
    paddingBottom: 10,
  },
});
function Category() {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.report.category);
  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Expenses Report</Text>

          <View>
            <Text>Reduce your rent</Text>
            <Text>Add savings</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
  const items = [
    {
      label: <div onClick={() => dispatch(setCategory("Bills"))}>Bills</div>,
      key: "0",
    },
    {
      label: (
        <div onClick={() => dispatch(setCategory("Expenses"))}>Expenses</div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div onClick={() => dispatch(setCategory("All Categories"))}>
          All Categories
        </div>
      ),
      key: "3",
    },
  ];
  return (
    <div className=" flex flex-wrap justify-between items-center border-y px-4 mt-2 py-4">
      <div className=" flex md:space-x-4 space-x-2 items-center">
        {/* <div className=" w-40">
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <a
              onClick={(e) => e.preventDefault()}
              className="text-blue-600 cursor-pointer"
            >
              <Space>
                {category}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div> */}
        <DatePicker />
      </div>
      <div className=" flex md:space-x-2 space-x-1 items-center text-blue-600 cursor-pointer">
        <MdFileCopy className="" />
        <PDFDownloadLink document={<MyDoc />} fileName="report.pdf">
          {({ blob, url, loading, error }) =>
            loading ? (
              <div>Loading document... </div>
            ) : (
              <div className=" text-sm">Export</div>
            )
          }
        </PDFDownloadLink>
      </div>
    </div>
  );
}

export default Category;
