import React, { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";

import houseIcon from "../../../assets/images/house-icon.png";
import spending from "../../../assets/images/spending.png";

import { Flex, Progress } from "antd";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import UpdateUtilityModal from "../modals/UpdateUtilityModal";
import UtilityModal from "../modals/UtilityModal";
import { IoIosAddCircleOutline } from "react-icons/io";
import DatePicker from "../buttons/DatePicker";

import {
  useGetExpensesQuery,
  useGetExpensesByDateAndCategoryQuery,
  useAddExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
} from "./expenseSlice";
import { useGetProfileQuery } from "../settings/profileSlice";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Category() {
  const currentDate = useSelector((state) => state.date);
  const date = new Date(currentDate.year, currentDate.month - 1);
  const monthName = date.toLocaleString("default", { month: "long" });
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  return (
    <div className="   bg-gray-100">
      <div className=" flex items-center justify-between">
        <div className=" flex space-x-2 items-center py-3">
          <div className="">
            <DatePicker />
          </div>
          <div className=" py-1 px-4 text-sm rounded-md  cursor-pointer  bg-[#5A82E3] text-white ">
            {monthName} {currentDate.year}
          </div>
        </div>
        <div className=" bg-red-500 py-0.5 text-sm px-4 text-white rounded-md ">
          Debt {profileData?.debt}
        </div>
      </div>
      <div className="  grid grid-cols-10 py-2 border-t border-gray-400 px-4">
        <div className=" col-span-3">
          <div className=" flex md:space-x-2 space-x-1 items-center">
            <div className="uppercase text-sm   text-gray-900 ">Category</div>
            <div className="  text-gray-900">
              <IoIosAddCircle className="" />
            </div>
          </div>
        </div>
      </div>
      <CategoryTable />
    </div>
  );
}

export default Category;

const CategoryTable = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUtility, setShowUtility] = useState(false);
  const [expenseName, setExpenseName] = useState();
  const [categoryName, setCategoryName] = useState();
  const [categoryId, setCategoryId] = useState();
  const [expenseId, setExpenseId] = useState();
  const [requiredAmount, setRequiredAmount] = useState(0);
  const [availableAmount, setAvailableAmount] = useState(0);
  const currentDate = useSelector((state) => state.date);
  const { data, isLoading, isSuccess, isFetching, refetch, isError, error } =
    useGetExpensesByDateAndCategoryQuery(currentDate, {
      refetchOnMountOrArgChange: true,
    });
  const { data: profileData, isLoading: loadingProfile } = useGetProfileQuery({
    refetchOnMountOrArgChange: true,
  });
  const [addExpense, { isLoading: loading, error: errorMsg }] =
    useAddExpenseMutation();
  const [updateExpense, { isLoading: loadingExpense, error: expenseErrorMsg }] =
    useUpdateExpenseMutation();
  const [
    deleteExpense,
    { isLoading: loadingDelExpense, error: expenseDelErrorMsg },
  ] = useDeleteExpenseMutation();
  const addOneExpense = async () => {
    try {
      const res = await addExpense({
        name: expenseName,
        categoryId: categoryId,
        categoryName: categoryName,
        available: availableAmount,
      }).unwrap();
      toast.success("Expense added successfully!");
      console.log(res);
      setShowUtility(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const updateOneExpense = async () => {
    try {
      const res = await updateExpense({
        expenseId: expenseId,
        amount: {
          required: requiredAmount,
          available: availableAmount,
        },
      }).unwrap();

      toast.success("Expense updated successfully!");

      console.log(res);
      setShowUpdate(false);
      setRequiredAmount(0);
      setAvailableAmount(0);
    } catch (error) {
      console.log(error);
      setRequiredAmount(0);
      setAvailableAmount(0);
      toast.error("Something went wrong!");
    }
  };
  const deleteOneExpense = async (newExpenseId) => {
    try {
      const res = await deleteExpense({
        expenseId: newExpenseId,
      }).unwrap();
      toast.success("Expense deleted successfully!");

      console.log(res);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  return (
    <div className=" w-full px-2 ">
      {data &&
        !isLoading &&
        data.map((item, index) => (
          <div className="w-full">
            <div className="  grid grid-cols-10 py-2 border-t border-gray-400  bg-gray-200 px-4">
              <div className=" col-span-4">
                <div className=" flex space-x-2 items-center">
                  <div className="uppercase text-sm text-black font-semibold pb-1 ">
                    {item?.categoryName}
                  </div>
                  <div
                    className=" cursor-pointer p-1 "
                    onClick={() => {
                      setCategoryName(item?.categoryName);
                      setCategoryId(item?.categoryId);
                      setShowUtility(true);
                    }}
                  >
                    <IoIosAddCircleOutline className="" />
                  </div>
                </div>
              </div>

              <div className=" col-span-4">
                <div className=" uppercase text-sm text-black ">Assigned</div>
              </div>
            </div>
            {item.expenses.map((expense) => (
              <div className=" grid grid-cols-10 gap-x-1 py-1 items-center border-b border-gray-400  px-4">
                <div className=" col-span-4">
                  <div className="">
                    <div className=" flex space-x-2 items-center">
                      <div className="">
                        <div className=" flex space-x-2 items-center">
                          <img src={spending} className=" w-5 h-5" alt="" />
                          <div className=" text-sm font-semibold   text-gray-900 ">
                            {expense?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" col-span-4">
                  <div className=" uppercase text-sm  text-gray-900 ">
                    {profileData?.currency} {expense?.amount?.available}
                  </div>
                </div>

                <div className=" col-span-2   flex justify-center py-1">
                  <div className=" flex items-center space-x-2  ">
                    <div
                      className=" text-blue-600 cursor-pointer hover:bg-gray-300 rounded-full p-1 "
                      onClick={() => {
                        setExpenseId(expense?.expenseId);
                        setShowUpdate(true);
                      }}
                    >
                      <MdEdit className="" />
                    </div>
                    <div
                      className=" text-red-500  cursor-pointer hover:bg-gray-300 rounded-full p-1 "
                      onClick={() => deleteOneExpense(expense?.expenseId)}
                    >
                      <MdDelete className="" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className=" grid grid-cols-10 mb-2 py-1 pb-4 items-center border-b border-gray-400  px-4">
              <div className=" col-span-4"></div>
              <div className=" col-span-4">
                <div className="text-green-500 text-sm">
                  {profileData?.currency}{" "}
                  {item.expenses.reduce(
                    (sum, expense) =>
                      sum + parseInt(expense?.amount?.available),
                    0
                  )}
                </div>
              </div>
              <div className=" col-span-2"></div>
            </div>
          </div>
        ))}

      {showUpdate && (
        <UpdateUtilityModal
          closeHandler={() => setShowUpdate(false)}
          setRequiredAmount={setRequiredAmount}
          setAvailableAmount={setAvailableAmount}
          requiredAmount={requiredAmount}
          availableAmount={availableAmount}
          updateOneExpense={updateOneExpense}
          loadingExpense={loadingExpense}
        />
      )}
      {showUtility && (
        <UtilityModal
          closeHandler={() => setShowUtility(false)}
          setExpenseName={setExpenseName}
          expenseName={expenseName}
          availableAmount={availableAmount}
          setAvailableAmount={setAvailableAmount}
          loading={loading}
          addOneExpense={addOneExpense}
        />
      )}
      <ToastContainer />
    </div>
  );
};
