import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import moneyBagSvg from "../../../assets/images/money.svg";
import { FcGoogle } from "react-icons/fc";
import {
  signInWithGoogle,
  signUpWithEmailAndPassword,
} from "../../../utils/config";
import SideBanner from "../SideBanner";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../utils/Spinner";
import { useResetPasswordMutation } from "./userSlice";
import logo from "../../../assets/images/logo.png";
import darkLogo from "../../../assets/images/darkLogo.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ResetPassword() {
  const navigate = useNavigate();
  const { resetLink } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState();
  const [resetPassword, { isLoading: loadingReset, error: resetErrorMsg }] =
    useResetPasswordMutation();

  const resetUserPassword = async () => {
    try {
      setError("");
      setSuccess("");
      if (password !== confirmPassword) {
        return toast.error("Password and confirm password do not match");
      }
      setPasswordError("");
      setLoading(true);
      const res = await resetPassword({
        newPassword: password,
        resetLink: resetLink,
      }).unwrap();
      console.log(res);
      toast.success("Password reset successful. Please Login.");
      // setSuccess("Password reset successful. Please Login.");
      setLoading(false);
      if (res.error) {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error?.data?.message);
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className=" flex  h-screen ">
      <div className=" md:w-1/2 w-full bg-white  h-full overflow-y-scroll flex justify-center p-10">
        <div className=" md:w-3/4 w-full ">
          <div className=" flex justify-center items-center">
            <img src={logo} className="w-60 h-16 rounded-lg" alt="" />
          </div>

          <div className=" text-sm text-gray-500 pb-4 text-center">
            Your Budgeting Companion
          </div>
          <div className=" text-sm text-gray-500 pt-4 text-center">
            Please create a new password
          </div>
          {/* {error.length > 1 && (
            <div className="text-sm text-red-500 pt-4 text-center capitalize">
              {error}
            </div>
          )}
          {success.length > 1 && (
            <div className="text-sm text-green-500 pt-4 text-center capitalize">
              {success}
            </div>
          )} */}
          {/* {passwordError.length > 1 && (
            <div className="text-sm text-red-500 pt-4 text-center capitalize">
              {passwordError}
            </div>
          )} */}

          <div className=" pt-12">
            <div className="text-gray-500 text-sm">Password</div>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder=" Enter Password"
              type="password"
              className=" border border-gray-800 py-2 px-2   shadow-lg"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className=" pt-4">
            <div className="text-gray-500 text-sm">Confirm Password</div>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder=" Confirm Password"
              type="password"
              className=" border border-gray-800 py-2 px-2   shadow-lg"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className=" pt-4  flex justify-between items-center">
            <div className=" flex items-center space-x-2"></div>
            {/* <Link
              to={"/login"}
              className=" text-sm text-gray-500 cursor-pointer"
            >
              Login
            </Link> */}
          </div>
          <div className=" flex justify-center items-center pt-4 w-full">
            <button
              className=" bg-[#13143E] text-white w-full py-1 rounded-full"
              onClick={resetUserPassword}
            >
              {loading ? <Spinner /> : " Submit"}
            </button>
          </div>

          <div className=" flex  justify-center items-center pt-4 w-full">
            <Link
              to={"/login"}
              className=" text-sm text-gray-500 cursor-pointer"
            >
              Go to Login
            </Link>
          </div>
          <div className="pt-8 flex items-center justify-center">
            <div className=" text-sm text-gray-500">
              Anthonomics 2024 | All rights reserved
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 md:block hidden   h-full overflow-y-scroll">
        <SideBanner />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ResetPassword;
