import React, { useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { MdAccountBalance } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaRegStar } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { CgLogOut } from "react-icons/cg";
import { Logout } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function ExpandedSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const onLogout = async () => {
    try {
      const res = await Logout();
      console.log(res);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" h-full flex flex-col   text-white px-4 pt-4">
      <div className=" bg-white rounded-md">
        <img src={logo} alt="" className="w-50 h-12 rounded-lg" />
      </div>
      <div className=" w-full flex  items-center justify-center space-x-4 pb-4"></div>

      <Link
        to="/dashboard"
        className={`${
          location.pathname == "/dashboard" && "bg-[#5C85E7]"
        }   mt-4 text-white cursor-pointer flex items-center space-x-4 px-8 py-1 rounded-md`}
      >
        <div className="">
          <FaMoneyCheckAlt className=" text-lg" />
        </div>
        <div className=" text-lg ">Budget</div>
      </Link>
      <Link
        to="/dashboard/reports"
        className={`${
          location.pathname == "/dashboard/reports" && "bg-[#5C85E7]"
        }   mt-4 text-white cursor-pointer flex items-center space-x-4 px-8 py-1 rounded-md`}
      >
        <div className="">
          <GoGraph className=" text-lg" />
        </div>
        <div className=" text-lg ">Reports</div>
      </Link>
      <Link
        to="/dashboard/accounts"
        className={`${
          location.pathname == "/dashboard/accounts" && "bg-[#5C85E7]"
        }   mt-4 text-white cursor-pointer flex items-center space-x-4 px-8 py-1 rounded-md`}
      >
        <div className="">
          <MdAccountBalance className=" text-lg" />
        </div>
        <div className=" text-lg ">Income</div>
      </Link>
      <Link
        to="/dashboard/settings"
        className={`${
          location.pathname == "/dashboard/settings" && "bg-[#5C85E7]"
        }   mt-4 text-white cursor-pointer flex items-center space-x-4 px-8 py-1 rounded-md`}
      >
        <div className="">
          <CiSettings className=" text-lg" />
        </div>
        <div className=" text-lg ">Settings</div>
      </Link>
      {/* <div className=" flex px-4 text-sm mt-6 items-start  space-x-4">
        <div className=" pt-1">
          <IoIosArrowForward className="" />
        </div>
        <div className="">
          <div className=" flex space-x-8 justify-between items-center">
            <div className="">Budget</div>
            <div className="">Ksh 80,900.00</div>
          </div>
          <div className=" flex space-x-8 justify-betweeen pt-4 items-center">
            <div className="">Balance</div>
            <div className="">Ksh 80,900.00</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

function Sidebar() {
  return (
    <div className=" h-full  bg-gray-900">
      <ExpandedSidebar />
    </div>
  );
}

export default Sidebar;
