import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineArrowRight } from "react-icons/ai";
import Spinner from "../utils/Spinner";

function FundModal({
  closeHandler,
  addOneIncome,
  setIncome,
  setAmount,
  setCategory,
  income,
  amount,
  category,
  loading,
}) {
  return (
    <>
      <div
        className="fixed w-full inset-0 z-10 overflow-hidden flex justify-center items-end md:items-center animated fadeIn faster"
        style={{ background: "rgba(0,0,0,.6)" }}
      >
        <div className="shadow-xl modal-container bg-[#2c396abe] text-white w-full md:w-3/5 lg:w-3/5 xl:w-1/3 mx-auto rounded-t-lg md:rounded-lg z-100 overflow-y-auto max-h-full">
          <div className="modal-content text-left">
            <div className="px-6 pb-6">
              <div className=" flex justify-between mt-4 mb-4">
                <div className=" text-green-400  text-lg font-semibold ">
                  Fund Account
                </div>
                <div
                  className="  p-2 hover:bg-gray-200 hover:text-black rounded-lg cursor-pointer"
                  onClick={closeHandler}
                >
                  <AiOutlineClose className="" />
                </div>
              </div>
              <div className="py-1">
                <label
                  htmlFor="required"
                  className="text-xs text-green-300
         "
                >
                  Enter Amount
                </label>
                <input
                  type="number"
                  name="required"
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                  className="w-full rounded-md text-white border p-1 placeholder-white text-sm  focus:outline-none focus:ring-0 focus:border-blue-600 bg-transparent"
                  placeholder="Amount"
                  required
                />
              </div>
              <div className="py-1">
                <label
                  htmlFor="required"
                  className="text-xs text-green-300
         "
                >
                  Source of Funds
                </label>
                <input
                  type="text"
                  name="required"
                  onChange={(e) => setIncome(e.target.value)}
                  value={income}
                  className="w-full rounded-md text-white border p-1 placeholder-white text-sm  focus:outline-none focus:ring-0 focus:border-blue-600 bg-transparent"
                  placeholder="eg Salary"
                  required
                />
              </div>

              <button
                className=" bg-green-400 mt-6 w-full flex justify-between items-center rounded-md px-4 py-1"
                onClick={addOneIncome}
              >
                <div className="">{loading ? <Spinner /> : "Submit"}</div>
                <div className="">
                  <AiOutlineArrowRight className="" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundModal;
