import React, { useState } from "react";
import { TbMathFunctionOff } from "react-icons/tb";
import { BsCurrencyExchange } from "react-icons/bs";

function Header() {
  return (
    <div className=" py-6 px-4 w-full overflow-x-hidden flex flex-wrap gap-y-2 justify-between items-center  text-gray-600">
      <div className="">
        <div className=" md:text-xl pb-1 uppercase font-semibold  text-gray-800">
          Profile Settings
        </div>
        <div className=" flex items-center space-x-4">
          <div className=" flex items-center space-x-1">
            <div className="">
              <BsCurrencyExchange className=" text-sm" />
            </div>
            <div className=" text-xs">Currency</div>
          </div>
          <div className=" flex items-center space-x-1">
            <div className="">
              <TbMathFunctionOff className=" text-sm" />
            </div>
            <div className=" text-xs">Formulae</div>
          </div>
        </div>
      </div>
      {/* <div className=" flex flex-wrap gap-x-2">
        <button className=" flex items-center bg-blue-700 px-3 space-x-1  py-1 rounded-xl text-white ">
          <div className=" text-sm ">Fund</div>
          <IoIosAddCircle className="" />
        </button>
        <button className=" flex items-center  px-3 space-x-1  py-1 rounded-xl bg-red-500 text-white ">
          <div className=" text-sm ">Delete </div>
          <MdDelete className="" />
        </button>
      </div> */}
    </div>
  );
}

export default Header;
