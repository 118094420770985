import React, { useEffect, useState } from "react";
import SideBanner from "../SideBanner";
import Spinner from "../utils/Spinner";
import { useVerifyEmailMutation } from "./userSlice";
import logo from "../../../assets/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";

function VerifyLink() {
  const { token } = useParams();
  const navigate = useNavigate();

  const [verifyEmail, { isLoading: loadingEmail, error: emailErrorMsg }] =
    useVerifyEmailMutation();
  useEffect(() => {
    const sendVerifyEmail = async () => {
      if (token) {
        try {
          const resEmail = await verifyEmail(token).unwrap(); // Await API call
          console.log(resEmail);
          toast.success("Email Verified!");
          navigate("/dashboard"); // Use 'navigate' to redirect instead of window.location.href
        } catch (error) {
          toast.error(error?.data?.message);
          console.log(error);
        }
      }
    };

    sendVerifyEmail();
  }, [token]);

  const sendVerifyingEmail = async () => {
    try {
      const resEmail = await verifyEmail(token).unwrap();
      console.log(resEmail);
      toast.success("Email Verified!");
      window.location.href = "/dashboard";
    } catch (error) {
      toast.error(error?.data?.message);
      console.log(error);
    }
  };
  return (
    <div className=" flex  h-screen ">
      <div className="md:w-1/2 md:block hidden   h-full overflow-y-scroll">
        <SideBanner />
      </div>
      <div className=" md:w-1/2 w-full bg-white  h-full overflow-y-scroll  px-4 md:py-10 py-8">
        <div className=" grid md:grid-cols-2 grid-cols-1   ">
          <div className="flex items-center md:justify-normal  justify-center ">
            <img src={logo} className="w-60 h-16 rounded-lg" alt="" />
          </div>
        </div>
        <div className="  w-full md:px-10 px-2 pt-6 ">
          <div className=" text-center text-xl font-bold ">Verifying Email</div>

          <div className=" flex justify-center items-center pt-4 w-full">
            <button
              className=" bg-[#13143E] text-white w-full py-1 rounded-md"
              onClick={sendVerifyingEmail}
            >
              {loadingEmail ? <Spinner /> : " Verifying Email"}
            </button>
          </div>
          <div className=" flex  justify-center items-center pt-4 w-full"></div>
          <div className="pt-8 flex items-center justify-center">
            <div className=" text-sm text-gray-500">
              Anthonomics 2024 | All rights reserved
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default VerifyLink;
